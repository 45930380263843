import { AppConfigService } from "./../../services/app-config.service";
import { Observable, Subject, of } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";
import { map, retry, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProfileService {

  get identity(): Identity {
    return this.oAuthService.getIdentityClaims() as Identity;
  }
  get profile(): Profile {
    const profile = window.localStorage.getItem("profile");
    return JSON.parse(profile || "{}");
  }
  set profile(val: Profile) {
    window.localStorage.setItem("profile", JSON.stringify(val));
  }
  get role() {
    const identity = this.oAuthService.getIdentityClaims() as Identity;
    if (identity) {
      return identity.userType;
    }
    return null;
  }

  get isStaff() {
    if(this.profile.role == 'Parent' || this.profile.role == 'Student'){
      return false
    } else {
      return true
    }
  }

  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices; // "https://localhost:44315"
  public pictureUpdate: Subject<any> = new Subject<any>();
  constructor(
    private oAuthService: OAuthService,
    private appConfig: AppConfigService,
    private api: ApiService
  ) {

  }
  getProfile(): Observable<Profile> {
    const path = `${this.baseUrl}/v1/profile/${this.identity.userIdRole}`;
    return this.api.getNoCache(path).pipe(
      tap((response) => {
        this.profile = response as Profile;
      }),
      map((response) => response as Profile)
    );
  }
  getProfileAsLogin(): Observable<Profile> {
    const path = `${this.baseUrl}/v1/profile/${this.identity.userIdRole}?isLogin=true`;
    return this.api.getNoCache(path).pipe(
      tap((response) => {
        this.profile = response as Profile;
      }),
      map((response) => response as Profile)
    );
  }
  getProfileById(id: any,cache:boolean = true): Observable<Profile> {
    const path = `${this.baseUrl}/v1/profile/${id}`;
    if(cache){
      return this.api.cached(path,{ spinner: "no" }).pipe(map((response) => response as Profile));
    }else{
      return this.api.getNoCache(path).pipe(map((response) => response as Profile));
    }

  }

  isParent() {
    return this.role === 'Parent' ? true : false;
   }

  cachedProfiles = []
  silentProfileById(id: any): Observable<Profile> {
    const path = `${this.baseUrl}/v1/profile/${id}`;


    return this.api
      .cached(path, { spinner: "no" })
      .pipe(map((response) => response as Profile));
  }

  getContactProfile(usernameNo): Observable<Profile> {
    const path = `${this.baseUrl}/v1/profile/${usernameNo}`;
    if (this.profile.usernameNo) {
      return of(this.profile);
    }
    return this.api.get(path).pipe(
      tap((response) => {
        this.profile = response as Profile;
      }),
      map((response) => response as Profile)
    );
  }
  saveProfile(profile: any): Observable<any> {
    const path = `${this.baseUrl}/v1/profile/${this.identity.userIdRole}`;
    return this.api
      .patch(path, profile)
      .pipe(map((response) => response as any));
  }
  setTheme(theme: any) {
    const path = `${this.baseUrl}/v1/profile/${this.identity.userIdRole}/theme`;
    return this.api.patch(path, theme).pipe(map((response) => response as any));
  }
  getTheme() {
    const path = `${this.baseUrl}/v1/profile/${this.identity.userIdRole}/theme`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  uploadAvatar(profileId: any, file: File, avatar:string):Observable<any> {
    let path = `${this.baseUrl}/v1/profile/${profileId}/avatar`;
    return this.api.postFile(path,{
      previousAvatar: avatar
    },file).pipe(map((response)=> response as any));
  }
  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
}
export interface Identity {
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
  nonce: string;
  iat: number;
  at_hash: string;
  s_hash: string;
  sid: string;
  sub: string;
  auth_time: number;
  idp: string;
  usernameNo: string;
  displayName: string;
  userType: string;
  userIdRole: string;
  amr: string[];
}
export interface Profile {
  lastLogin: any;
  role: string;
  id: string;
  picture: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  email: string;
  bio: string;
  url: string;
  language: string;
  nickname: string;
  handbook: boolean;
  hasTour: boolean;
  notification: NotificationGroup;
  userType: string;
  usernameNo: string;
  themeSettings: any;
  avatar: any;
  accountEnabled:boolean;
  studentExceptionEnabled:boolean;
}
export interface NotificationGroup {
  feedCount: number;
  messageCount: number;
  commentCount: number;
}
