import { RoleServiceFactoryService } from "./../../services/role-service-factory.service";
import { Component, Input, OnInit } from "@angular/core";
import { ProfileService } from "./../../../shared/services/profile.service";
import { DaysTipModel } from "./models/DaysTipModel";
import { CertificateService } from "src/app/shared/services/certificate.service";
import * as moment from "moment";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";
import { AppConfigService } from "src/app/services/app-config.service";

@Component({
  selector: "app-tips-day-widget",
  templateUrl: "./tips-day-widget.component.html",
  styleUrls: ["./tips-day-widget.component.scss"],
})
export class TipsDayWidgetComponent implements OnInit {
  @Input() model: DaysTipModel = null;

  loaded: boolean;
  today = moment().day();
  role: any = null;
  isMotivation: boolean = false;

  isProd = environment.production;

  studentId: any;
  email: any;
  token: any;
  action: any;
  ssoUrl: any;

  constructor(
    private certificateServices: CertificateService,
    private profileService: ProfileService,
    private rs: RoleServiceFactoryService,
    private oauthService: OAuthService,
    private appConfigService: AppConfigService
  ) {}

  settings = null;

  ngOnInit(): void {
    this.role = this.profileService.role;
    this.checkMotivation();
    this.certificateServices.getThumbnails().subscribe((settings) => {
      this.settings = settings;
    });

    console.log(this.model, "model");
  }

  getTitle() {
    if (this.role !== this.rs.ParentRole && this.role !== this.rs.StudentRole) {
      switch (this.today) {
        case 1:
          return "Mindful Monday";
        case 2:
          return "Grati-Tuesday";
        case 3:
          return "Wellness Wednesday";
        case 4:
          return "Thoughtful Thursday";
        case 5:
          return "Fun Friday";
        default:
          return "Weekend";
      }
    } else return;
  }

  checkMotivation() {
    if (this.role == "Counselor") {
      this.isMotivation = true;
    } else if (this.role == "Teacher") {
      this.isMotivation = true;
    } else if (this.role == "Administrator") {
      this.isMotivation = true;
    } else {
      this.isMotivation = false;
    }
  }

  initFormValues() {
    this.studentId = "";
    this.email = this.profileService.profile.email;
    var accessToken = this.profileService.parseJwt(
      this.oauthService.getAccessToken()
    );
    this.token = accessToken["idcs-auth-token"];
  }

  requestDevice() {
    if (this.model.url == "https://device.lausd.net") {
      this.initFormValues();
      this.ssoUrl =
        this.appConfigService.configuration.environment.configuration.ppServices.externalLogin.myapps;
      this.action = "DeviceRequest";
      setTimeout(() => {
        document.forms["stuEnr"].submit();
      }, 1000);
    } else {
      window.open(this.model.url);
    }
  }
}
