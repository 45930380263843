import { AccomplishmentService } from './../../services/accomplishment.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTheRedYeti } from '@fortawesome/free-brands-svg-icons';
import { SchoolYearService } from '../../services/school-year.service';
import { StudentsService } from '../../services/students.service';
import { ProfileService } from '../../services/profile.service';


@Component({
  selector: 'app-add-accomplishment-modal',
  templateUrl: './add-accomplishment-modal.component.html',
  styleUrls: ['./add-accomplishment-modal.component.scss']
})
export class AddAccomplishmentModalComponent implements OnInit {

  type: any;
  editMode: false;
  viewMode: false;
  mode = 'add';

  awardForm: FormGroup;
  awardLevels = ['City', 'State', 'National'];
  awardTypes = ['Athletics', 'Academics', 'Contest', 'Scholarships', 'Community Service', 'Certificates', 'Leadership'];
  years = ['9th', '10th', '11th', '12th'];


  edProgramForm: FormGroup;
  edPrograms = ['GEAR UP', 'College Match', 'Questbridge', 'SAT Prep', 'AP Readiness', 'MESA', 'AVID', 'Upward Bound'];


  extraCurricularForm: FormGroup;
  extraCurrTypes = ['Sports', 'Clubs', 'Band']


  collegeForm: FormGroup;
  collegeTypes = ['Elective', 'College Class', 'Peer Counseling Class', 'Leadership Class', 'Certificate Class'];
  semesters = ['Summer', 'Fall', 'Winter', 'Spring'];
  semesterYears: [] = [];

  workExpForm: FormGroup;


  volunteerForm: FormGroup;


  constructor(
    public dialogRef: MatDialogRef<AddAccomplishmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accomplishmentService: AccomplishmentService,
    private studentsService: StudentsService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.type = this.data.type;
    this.editMode = this.data.editMode;
    this.viewMode = this.data.viewMode;
    this.initForms(this.type);
    if(this.editMode){
      this.mode = 'edit';
      this.fillForm(this.type,this.data.item)
    }
    if(this.viewMode){
      this.mode = 'view';
      this.fillForm(this.type,this.data.item)
    }

    this.studentsService.getStudentYears(this.profileService.profile.id).subscribe(res => {
      this.semesterYears = res.yearList;
    })

  }

  formModel(type){
    var model = {} as any;
    switch (type) {
      case 'Awards':
        if(!this.awardForm.valid)
          return;
        model = this.awardForm.value;
        break;
      case 'EdPrograms':
        if(!this.edProgramForm.valid)
          return;
        model = this.edProgramForm.value;
        model.year = model.year.toString();
        break;
      case 'ExtraCurricular':
        if(!this.extraCurricularForm.valid)
          return;
        model = this.extraCurricularForm.value;
        model.year = model.year.toString();
        break;

      case 'CollegeClasses':
        if(!this.collegeForm.valid)
          return;
        model = this.collegeForm.value;
        break;

      case 'WorkExp':
        if(!this.workExpForm.valid)
          return;
        model = this.workExpForm.value;
        model.year = model.year.toString();
        break;

      case 'Volunteer':
        if(!this.volunteerForm.valid)
          return;
        model = this.volunteerForm.value;
        model.year = model.year.toString();
        break;

      default:
        break;
    }
    model.accomplishmentCategory = type;
    return model;
  }

  addAccomplishment(type) {
   var model = this.formModel(type);
    this.accomplishmentService.create(model).subscribe(()=>{
      this.dialogRef.close({valid:true,category:type});
    })
  }

  editAccomplishment(type) {
    var model = this.formModel(type);
    model.id = this.data.item.id;
    this.accomplishmentService.update(model).subscribe(()=>{
      this.dialogRef.close({valid:true,category:type});
    })
  }

  getTitle(type) {
    switch (type) {
      case 'Awards':
        return 'Awards/Honors'

      case 'EdPrograms':
        return 'Educational Programs'

      case 'ExtraCurricular':
        return 'Extra Curricular Activities'

      case 'CollegeClasses':
        return 'College Classes'

      case 'WorkExp':
        return 'Work Experience'

      case 'Volunteer':
        return 'Volunteer Experience'

      default:
        break;
    }
  }

  initForms(type){
    switch (type) {
      case 'Awards':
        this.awardForm = new FormGroup({
          name: new FormControl('', {
            validators: [Validators.required],
          }),
          level: new FormControl('', {
            validators: [Validators.required],
          }),
          type: new FormControl('', {
            validators: [Validators.required],
          }),
          year: new FormControl('', {
            validators: [Validators.required],
          }),
          efforts: new FormControl('', {
            validators: [Validators.required],
          }),
          eligibility: new FormControl('', {
            validators: [Validators.required],
          })
        });
        break;

      case 'EdPrograms':
        this.edProgramForm = new FormGroup({
          organization: new FormControl('', {
            validators: [Validators.required],
          }),
          description: new FormControl('', {
            validators: [Validators.required],
          }),
          year: new FormControl('', {
            validators: [Validators.required],
          }),
          program: new FormControl('', {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl('', {
            validators: [Validators.required],
          })
        })
        break;

      case 'ExtraCurricular':
        this.extraCurricularForm = new FormGroup({
          activity: new FormControl('', {
            validators: [Validators.required],
          }),
          description: new FormControl('', {
            validators: [Validators.required],
          }),
          year: new FormControl('', {
            validators: [Validators.required],
          }),
          type: new FormControl('', {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl('', {
            validators: [Validators.required],
          }),
        })
        break;

      case 'CollegeClasses':
        this.collegeForm = new FormGroup({
          school: new FormControl('', {
            validators: [Validators.required],
          }),
          course: new FormControl('', {
            validators: [Validators.required],
          }),
          grade: new FormControl('', {
            validators: [Validators.required],
          }),
          year: new FormControl('', {
            validators: [Validators.required],
          }),
          type: new FormControl('', {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl('', {
            validators: [Validators.required],
          }),
          program: new FormControl('', {
            validators: [Validators.required],
          }),
        })
        break;

      case 'WorkExp':
        this.workExpForm = new FormGroup({
          organization: new FormControl('', {
            validators: [Validators.required],
          }),
          description: new FormControl('', {
            validators: [Validators.required],
          }),
          year: new FormControl('', {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl('', {
            validators: [Validators.required],
          }),
        })
        break;

      case 'Volunteer':
        this.volunteerForm = new FormGroup({
          organization: new FormControl('', {
            validators: [Validators.required],
          }),
          description: new FormControl('', {
            validators: [Validators.required],
          }),
          year: new FormControl('', {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl('', {
            validators: [Validators.required],
          }),
        })
        break;

      default:
        break;
    }





  }

  fillForm(type,item){
    let isDisabled = false;
    if(this.viewMode){
      isDisabled = true;
    }
    switch (type) {
      case 'Awards':
        this.awardForm = new FormGroup({
          name: new FormControl({value: item.name, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          level: new FormControl({value: item.level,disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          type: new FormControl({value: item.type, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          year: new FormControl({value: item.year, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          efforts: new FormControl({value: item.efforts, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          eligibility: new FormControl({value: item.eligibility, disabled: isDisabled}, {
            validators: [Validators.required],
          })
        });
        break;

      case 'EdPrograms':
        this.edProgramForm = new FormGroup({
          organization: new FormControl({value: item.organization, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          description: new FormControl({value: item.description, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          year: new FormControl({value: item.year.split(','), disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          program: new FormControl({value: item.program, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl({value: item.hoursPerWeek, disabled: isDisabled}, {
            validators: [Validators.required],
          })
        })
        break;

      case 'ExtraCurricular':
        this.extraCurricularForm = new FormGroup({
          activity: new FormControl({value: item.activity, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          description: new FormControl({value: item.description, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          year: new FormControl({value: item.year.split(','), disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          type: new FormControl({value: item.type, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl({value: item.hoursPerWeek, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
        })
        break;

      case 'CollegeClasses':
        this.collegeForm = new FormGroup({
          school: new FormControl({value: item.school, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          course: new FormControl({value: item.course, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          grade: new FormControl({value: item.grade, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          year: new FormControl({value: item.year, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          type: new FormControl({value: item.type, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl({value: item.hoursPerWeek, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          program: new FormControl({value: item.program, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
        })
        break;

      case 'WorkExp':
        this.workExpForm = new FormGroup({
          organization: new FormControl({value: item.organization, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          description: new FormControl({value: item.description, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          year: new FormControl({value: item.year.split(','), disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl({value: item.hoursPerWeek, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
        })
        break;

      case 'Volunteer':
        this.volunteerForm = new FormGroup({
          organization: new FormControl({value: item.organization, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          description: new FormControl({value: item.description, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          year: new FormControl({value: item.year.split(','), disabled: isDisabled}, {
            validators: [Validators.required],
          }),
          hoursPerWeek: new FormControl({value: item.hoursPerWeek, disabled: isDisabled}, {
            validators: [Validators.required],
          }),
        })
        break;

      default:
        break;
    }





  }

  onClose() {
    this.dialogRef.close({valid: false});
  }


}
