import { ProfileService } from "./profile.service";
import { IRoleServices } from "./IRoleServices";
import { AppConfigService } from "../../services/app-config.service";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ContactModel } from "../components/contact-widget/models/ContactWidgetModel";
import { IEmployeeContact } from "./parent.services.models";

@Injectable({
  providedIn: "root",
})
export class AdministratorService implements IRoleServices {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.administratorServices;
  baseCustomerUrl: string = this.appConfig.configuration.environment.endPoints
    .customerServices;

  public teacherStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private profileService: ProfileService
  ) {
    this.api.baseUrl = this.baseUrl;
  }

  getContacts(id: string): Observable<ContactModel[]> {
    const path = `${this.baseCustomerUrl}/v1/administrator/${id}/contacts`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getPinDetails(studentId: string): Observable<any> {
    const path = `${this.baseUrl}/v1/contacts/pin-details?studentId=${studentId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  setAvailability(request: any): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/quiethours/${userId}/availability`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  adminSearch(filter: string, role: string): Observable<IEmployeeContact[]> {
    let path = `${this.baseUrl}/v1/contacts?filter=${filter}&role=${role}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IEmployeeContact[]));
  }

  getSchoolLocations(): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    let path = `${this.baseUrl}/v1/locations/${userId}`;
    return this.api
    .get(path)
    .pipe(map((response) => response as any));
  }

  resetPin(request): Observable<any> {
    // const userId = this.profileService.identity.userIdRole;
    const userId = this.profileService.profile.id;
    const path = `${this.baseUrl}/v1/operations/${userId}/update-pin`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }


  addException(request): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/operations/${userId}/student-exception`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  deactivateParentAccount(request): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/operations/${userId}/toggle-account`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  activateParentAccount(request): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/operations/${userId}/toggle-account`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  getParentFromMisis(request): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/operations/${userId}/misis-parent`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
  getParentFromMisis2(request,parentId): Observable<any> {
    const path = `${this.baseUrl}/v1/operations/${parentId}/misis-parent`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  importParentFromMisis(request): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/operations/${userId}/import-parent`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
}
