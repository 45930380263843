
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { routes } from './routes';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
  ],

  exports: [
    RouterModule
  ]
})

export class RoutesModule {
  // constructor(
  //   public menuService: MenuService,
  //   tr: TranslatorService,
  //   public settings:SettingsService,
  //   private profileService:ProfileService,
  //   private oAuthService: OAuthService) {

  //    this.profileService.getProfile().subscribe((profile)=>{
  //     let menu = menuProfile[settings.user.profile]
  //       menuService.addMenu(menu);
  //    })

  // }
}
