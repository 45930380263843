import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ContactModel } from "src/app/shared/components/contact-widget/models/ContactWidgetModel";
import { ContactsService } from "src/app/shared/services/contacts.service";
import { IEmployeeContact } from "src/app/shared/services/parent.services.models";
import { ParentsService } from "src/app/shared/services/parents.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import { ProfileService } from "src/app/shared/services/profile.service";
import swal from "sweetalert";
import { NotificationServices } from "src/app/shared/services/notification.service";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-customer-contacts",
  templateUrl: "./customer-contacts.component.html",
  styleUrls: ["./customer-contacts.component.scss"],
})
export class CustomerContactsComponent implements OnInit {
  @Input() contacts: ContactModel[] = null;
  isSearch = false;
  duplicateContactData: any;
  parentData: any;
  byRoleFilter: any;
  getParentByFilter: any;
  searchContactDetails: any;
  getStudentByFilter: any = "";
  studentData: any;

  isLoading = false;
  role = this.profileService.profile.role;

  constructor(
    private parentService: ParentsService,
    private contactsService: ContactsService,
    private router: Router,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    public rs: RoleServiceFactoryService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getParentByFilter = "";
    this.byRoleFilter = "";
    this.searchContactDetails = "";

    this.isLoading = true;

    if (this.role == "Student") {
      this.initStudent();
    }

    if (this.role == "Parent") {
      this.initParent();
    }
  }

  initStudent() {
    this.contactsService.getContacts().subscribe((contacts) => {
      this.isLoading = false;
      this.contacts = contacts;
      this.duplicateContactData = JSON.parse(JSON.stringify(contacts));
      this.parentData = _.sortBy(
        this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.StudentRole;
        }),
        ["empLastName"]
      );

      this.startTour();
    }, error => {
      this.isLoading = false;
      this.toastr.error(`Can't load Contacts`);
    });
  }

  initParent() {
    this.parentService.getContactsByParent().subscribe((response) => {
      this.isLoading = false;
      this.contacts = response;
      this.duplicateContactData = JSON.parse(JSON.stringify(response));
      this.studentData = _.sortBy(
        this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.StudentRole;
        }),
        ["empLastName"]
      );

      this.studentData.unshift({ empCode: "" });
    });

    this.startTour();
  }

  command(cmd: any) {
    this.contactsService.setContact(cmd.source);
    if (cmd.name == "message") {
      const contact = cmd.source;
      this.router.navigate(["student", "inbox", "compose", contact.empCode]);
    }
    if (cmd.name == "profile") {
      this.contactsService.setContact(cmd.source);
      const contact = cmd.source;
      console.log(contact, cmd);
      // this.dialog.open(ContactProfileComponent, { panelClass: 'contact-profile-dialog', maxWidth: 'auto' });
      this.router.navigate(["student", "contacts", "profile", contact.empCode]);

      // const contact = cmd.source;
      // this.router.navigate(['student', 'contacts', 'profile', contact.empCode]);
    }
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: "above" };
    let below: MdMenuPlacement = { yPosition: "below" };
    let left: MdMenuPlacement = { xPosition: "before" };
    let right: MdMenuPlacement = { xPosition: "after" };

    this.tourService.initialize([
      {
        anchorId: "start-tour",
        content:
          "Here you can access contact information regarding your teachers and school",
        title: "Welcome to your Contacts!",
      },
    ]);
    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: "warning",
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, turn off tour",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  contactFilterByCategory(evt: any) {
    if(!this.getStudentByFilter){
      if (evt === "by_principal") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.PrincipalRole;
        });
      } else if (evt === "by_teacher") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.TeacherRole;
        });
      } else if (evt === "by_parent") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.ParentRole;
        });
      } else if (evt === "by_student") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.StudentRole;
        });
      } else if (evt === "by_counselor") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.CounselorRole;
        });
      } else if (evt === "by_admin") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.AdminRole;
        });
      } else {
        this.contacts = this.duplicateContactData;
      }
    } else {
      if (evt === "by_principal") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.PrincipalRole && val.studentDistrictId.includes(this.getStudentByFilter);
        });
      } else if (evt === "by_teacher") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.TeacherRole && val.studentDistrictId.includes(this.getStudentByFilter);
        });
      } else if (evt === "by_parent") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.ParentRole && val.studentDistrictId.includes(this.getStudentByFilter);
        });
      } else if (evt === "by_student") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.StudentRole && val.studentDistrictId.includes(this.getStudentByFilter);
        });
      } else if (evt === "by_counselor") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.CounselorRole && val.studentDistrictId.includes(this.getStudentByFilter);
        });
      } else if (evt === "by_admin") {
        this.contacts = this.duplicateContactData.filter((val: any) => {
          return val.role === this.rs.AdminRole && val.studentDistrictId.includes(this.getStudentByFilter);
        });
      } else {
        this.contacts = this.duplicateContactData;
      }
    }
  }

  contactFilterByStudent(studentVal) {
    this.byRoleFilter = "";
    this.searchContactDetails = "";
    if (studentVal) {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        if (val.studentDistrictId.length) {
          const getStudent = val.studentDistrictId.filter((vals) => {
            return vals === studentVal;
          });
          return getStudent.length;
        } else {
          return false;
        }
      });
    } else {
      this.contacts = this.duplicateContactData;
    }
  }
  openContactSearch() {
    this.isSearch = !this.isSearch;
    this.searchContactDetails = "";
    setTimeout(() => {
      if (!this.isSearch) {
        this.contacts = this.duplicateContactData;
      }
    }, 500);
  }

  filterAllContacts() {
    this.getParentByFilter = "";
    this.byRoleFilter = "";
    if (this.searchContactDetails) {
      this.contacts = this.duplicateContactData.filter((vals: any) => {
        if (vals.empFirstName == null || vals.empLastName == null) {
          return;
        } else {
          const fullName =
            vals.empLastName.toLowerCase() +
            " " +
            vals.empFirstName.toLowerCase();
          return (
            fullName.indexOf(this.searchContactDetails.toLowerCase()) !== -1
          );
        }
      });
    } else {
      this.contacts = this.duplicateContactData;
    }
  }

  ngOnChanges() {
    if (this.contacts) {
      this.duplicateContactData = JSON.parse(JSON.stringify(this.contacts));
    }
  }
}
