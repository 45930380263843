import { AppConfigService } from './../../services/app-config.service';
import { ProfileService } from './../../shared/services/profile.service';
import { ApiService } from './../../shared/services/api.service';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as uuid from 'uuid';



@Injectable()
export class TranslatorService {


    private defaultLanguage: string = 'en';
    baseUrl: string = this.appConfig.configuration.environment.endPoints.communicationsServices
    lastLng = null;
    private availablelangs = [
        { code: 'en', text: 'English' },
        { code: 'es', text: 'Spanish' },
        { code: 'it', text: 'Italian' },
        { code: 'fa', text: 'Persian' },
        { code: 'ko', text: 'Korean' },
        { code: 'hy', text: 'Armenian' },
        { code: 'ru', text: 'Russian' },
        { code: 'tl', text: 'Tagalog' },
        { code: 'vi', text: 'Vietnamese' },
        { code: 'zh', text: 'Chinese' },
    ];

    constructor(
      public translate: TranslateService,
      private profileService: ProfileService,
      private appConfig: AppConfigService,
      private api: ApiService) {

        if (!translate.getDefaultLang())
            translate.setDefaultLang(this.defaultLanguage);

        this.useLanguage();
        setInterval(()=>{
          this.processTranslate();
        },5000)

    }

    useLanguage(lang: string = null) {
        this.translate.use(lang || this.translate.getDefaultLang());
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }
    translateWords(words,source) {
      let lng = this.profileService.profile.language;
      if(!this.lastLng)
        this.lastLng = this.defaultLanguage;
      if(lng == null)
        lng = 'en'
      if(source == '')
        source = 'en';
      let path = `${this.baseUrl}/v1/translator?source=${this.lastLng}&target=${lng}`;
      return this.api.post(path, words).pipe(map((response)=>response as any));
    }
    defaultLanguageMap = [];
    processTranslate(){
      if(!this.profileService.profile)
        return;
      var words = []
      var tags = []
      var currentLng = this.profileService.profile.language;
      var elements = document.querySelectorAll(`body [v3-translate]`)
      if(currentLng == this.defaultLanguage)
      return;
      elements.forEach(e=>{
        var lang = e.getAttribute('v3-translate');
        if(lang != currentLng) {
          if(currentLng != this.defaultLanguage){
            var translateSource = e.getAttribute('v3-translate-source');
            if(translateSource == undefined){
              e.setAttribute('v3-translate-id',e.textContent);
            }
          }
          if(e.childNodes.length == 1){
            if(e.childNodes[0].nodeValue){
              tags.push(e)
              words.push(e.childNodes[0].nodeValue)
            }
          }
        }

      })
      if(tags.length > 0 && this.lastLng != currentLng) {
        let translatorObs =   this.translateWords(words, this.lastLng);
        if(translatorObs)
          translatorObs.subscribe((result)=>{
            result.forEach((r,i)=>{
              tags[i].setAttribute('v3-translate',currentLng);
              tags[i].innerHTML = r.target;
            })
          });
      }
    }
}
var observeDOM = (function(){
  var MutationObserver = window.MutationObserver || (window as any).WebKitMutationObserver;

  return function( obj, callback ){
    if( !obj || obj.nodeType !== 1 ) return;

    if( MutationObserver ){
      // define a new observer
      var mutationObserver = new MutationObserver(callback)

      // have the observer observe foo for changes in children
      mutationObserver.observe( obj, { childList:true, subtree:true })
      return mutationObserver
    }

    // browser support fallback
    else if( window.addEventListener ){
      obj.addEventListener('DOMNodeInserted', callback, false)
      obj.addEventListener('DOMNodeRemoved', callback, false)
    }
  }
})()
