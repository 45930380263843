import { IStudentResume } from "./../../../../shared/components/student-list-widget/models/IStudentResume";
import { IStudentDetails } from "./../../../../shared/components/student-list-widget/models/IStudentDetails";
import { IStudentReport } from "src/app/shared/services/student.service.models";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IFaqGroup } from "src/app/shared/components/faq-section-widget/models/Faq";
import { StudentsService } from "src/app/shared/services/students.service";
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import * as _ from "lodash";
import * as m from 'moment';
import { ProfileService } from "src/app/shared/services/profile.service";
import swal from 'sweetalert';
import { NotificationServices } from "src/app/shared/services/notification.service";


@Component({
  selector: "app-student-testing",
  templateUrl: "./student-testing.component.html",
  styleUrls: ["./student-testing.component.scss"],
})
export class StudentTestingComponent implements OnInit {
  reportId: string = "CAASPP";
  reportYear: string = null;
  studentId: string = null;
  reportResponse: IStudentReport = null;
  message: any = '';
  student: IStudentResume = null;
  lastUpdated: any;
  pfts: any = null;
  olsat: any = null;
  ap: any = null;
  psat: any = null;
  testNames = [
    { id: "CAASPP", desc: "", gradeMin: "K", gradeMax: 12, name: "California Assessment of Student Performance and Progress" },
    { id: "ELPAC", desc: "", gradeMin: "K", gradeMax: 12, name: "English Language Proficiency Assessments for California" },
    { id: "PFT", desc: "", gradeMin: "K", gradeMax: 12, name: "Physical Fitness Test" },
    { id: "OLSAT", desc: "", gradeMin: 1, gradeMax: 5, name: "Otis-Lennon School Ability Test" },
    { id: "AP", desc: "", gradeMin: 6, gradeMax: 12, name: "Advanced Placement" },
    { id: "DIBLES", desc: "", gradeMin: 1, gradeMax: 5, name: "Dynamic Indicators of Basic Early Literacy Skills" },
    { id: "PSAT", desc: "", gradeMin: 6, gradeMax: 12, name: "Preliminary SAT" },
  ];
  years: any = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((data) => {
      console.log(data)
      if (data.reportType) {
        this.reportId = data.reportType
      }
    })
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.lastUpdated = m();
    });
    this.student = this.studentService.currentStudent();
    this.studentId = this.student.studentId;

    this.studentService.getStudentYears(this.studentId).subscribe(res => {
      _.each(res.yearList, year => {

        let value = year.slice(5, 9);

        let yearModel = { years: year, value: value }
        this.years.push(yearModel)
      })
   
      this.reportYear = this.years[0].value;
    })
    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'Your results from various tests will be displayed here',
      title: 'Student Testing',
      placement: right,
    },
    {
      anchorId: 'test0',
      content: 'Select a test by clicking on its title...',
      title: 'Step 1',
      placement: above,
    },
    {
      anchorId: 'year',
      content: 'Then select a year to download results from that grade year and click <b>Download</b>',
      title: 'Step 2',
      placement: below,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  ngOnChanges() {
    this.cdRef.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }


  getReport() {
    this.cdRef.detectChanges();
    this.message = 'Please wait while we download your report';

    if (this.reportId == "PFT") {
      this.getPft();
    } else if (this.reportId == "OLSAT") {
      this.getOlsat();
    } else if (this.reportId == "AP") {
      this.getAp();
    } else if (this.reportId == "PSAT") {
      this.getPsat();
    } else {
      let reportName = this.reportId;
      let reportFilter = this.reportId;
      if(this.reportId == "ELPAC"){
        reportName = "CAASPP"
        reportFilter = "ESA"
      }

      this.studentService
        .getStudentReport(this.studentId, reportName, this.reportYear)
        .subscribe((response) => {
          this.message = 'Download was successful. Click to view.'
          this.reportResponse = response;
          if(response)
            this.reportResponse.studentTestResource = this.reportResponse.studentTestResource.filter(r=>r.type == reportFilter);
        }, error => {this.message = 'Please check your internet connection and retry.'});
    }
  }

  hasReport(report: IStudentReport): boolean {

    if (report == null) {
      this.message = '';
      return false;
    }
    if (report.studentTestResource == null) {
      this.message = '';
      return false
    }
    if (report.studentTestResource.length == 0) {
      this.message = '';
      return false
    }
    return true;
  }
  
  hasOlsatReport(olsatReport) {
    if (olsatReport.years == null) return false
    else return true
  }

  testFilter(tests: any) {
    return tests.filter((t) => {
      return (
        (this.student?.gradeLevel?.toString() === t.gradeMin.toString() ||
          this.student.gradeLevel >= t.gradeMin ||
          (t.gradeMin === "K" && this.student.gradeLevel >= "1")) &&
        this.student.gradeLevel <= t.gradeMax
      );
    });
  }
  getPft() {
    this.studentService
      .getStudentPFTReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.message = '';
        this.pfts = this.processResponse(response);
      });

  }
  getPsat() {
    this.studentService
      .getStudentPsatReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.message = '';
        this.psat = response.years;
      });
  }
  getAp() {
    this.studentService
      .getStudentApReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.message = '';
        this.ap = response.years;
      });
  }
  getOlsat() {
    this.studentService
      .getStudentOlsatReport(this.studentId, this.reportYear)
      .subscribe((response) => {
        this.olsat = this.processOlsatResponse(response);
        if(this.olsat.years !== null) {
          this.olsat.year = this.olsat.years[0].schoolYear;
        }
        this.message = '';
      });
  }
  processApResponse(response) {
    var groups = _.groupBy(response.years, "testGroup");
    return {
      year: response.years[0].testDate.substring(0, 4),
      totalVerbal: groups["VERBAL"].reduce(
        (acc, row) => acc + parseInt(row.numberOfItems),
        0
      ),
      totalNonVerbal: groups["NONVERBAL"].reduce(
        (acc, row) => acc + parseInt(row.numberOfItems),
        0
      ),
      totalTotal: groups["TOTAL"].reduce(
        (acc, row) => acc + parseInt(row.numberOfItems),
        0
      ),
    };
  }
  processOlsatResponse(response) {
    // var groups = _.groupBy(response.years, "testGroup");
    // return {
    //   year: response.years[0].testDate.substring(0, 4),
    //   totalVerbal: groups["VERBAL"].reduce(
    //     (acc, row) => acc + parseInt(row.numberOfItems),
    //     0
    //   ),
    //   totalNonVerbal: groups["NONVERBAL"].reduce(
    //     (acc, row) => acc + parseInt(row.numberOfItems),
    //     0
    //   ),
    //   totalTotal: groups["TOTAL"].reduce(
    //     (acc, row) => acc + parseInt(row.numberOfItems),
    //     0
    //   ),
    // };
    return response
  }
  processResponse(response) {
    var rows = [];

    let ret = {
      year: "",
      rowsCount: 0,
      areas: [],
      rows: [],
    };
    for (let yearIdx in response.years) {
      let year = response.years[yearIdx];
      ret.year = year.schoolYear;
      for (let examIdx in year.pftExamList) {
        let exam = year.pftExamList[examIdx];
        ret.areas.push(exam.category);
        for (let testIdx in exam.pftTestList) {
          let test = exam.pftTestList[testIdx];
          let r = {
            area: exam.category,
            testName: test.testName,
            testValue: test.testValue,
            standardMet: 'Yes',
            hfzStandard: ''
          };
          if(r.testValue == null){
            r.standardMet = 'No';
            r.testValue = 'N/A'
          }
          switch (r.testName) {
            case 'Curl Ups':
              r.hfzStandard = `≥ ${year.healthZone.curlup_min}`
              break;
            case 'Best VO2':
              r.hfzStandard = `≥ ${year.healthZone.vo2_min}`
              break;
            case 'Sit Reach Left':
              r.hfzStandard = `≥ ${year.healthZone.sit_reach}`
              break;
            case 'Sit Reach Right':
              r.hfzStandard = `≥ ${year.healthZone.sit_reach}`
              break;
            case 'Trunk Lifts':
              r.hfzStandard = `≥ ${year.healthZone.trklift_min}`
              break;
            case 'BMI':
              r.hfzStandard = `≤ ${year.healthZone.bmi_min}`
              break;
            case 'Push Ups':
              r.hfzStandard = `≥ ${year.healthZone.pushup_min}`
              break;
            case 'Modified Pull Ups':
              r.hfzStandard = `≥ ${year.healthZone.pullup_min}`
              break;
            case 'Fixed Arm Hang':
              r.hfzStandard = `≥ ${year.healthZone.flex_arm_min}`
              break;

            default:
              break;
          }
          rows.push(r);
        }
      }
    }
    ret.rowsCount = rows.length;
    ret.rows = rows;
    console.log(ret, 'ret===', response)
    return ret;
  }

  testDetails(testId: string) {
    this.reportId = testId;
    this.reportResponse = null;
  }
}
