import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RemindersService } from '../../services/reminders.service';
import { ProfileService } from '../../services/profile.service';
import { ReminderDetails } from './models/ReminderDetails';
import { ReinforcementDetails } from '../add-reinforcement-modal/models/ReinforcementDetails';

@Component({
  selector: 'app-add-reminder-modal',
  templateUrl: './add-reminder-modal.component.html',
  styleUrls: ['./add-reminder-modal.component.scss']
})
export class AddReminderModalComponent implements OnInit {

  rMinutes = 1;
  rExpectation: any = 'Please select the expectation';
  staffId = null;

  play = true;

  student: any = null;
  expectations: ReinforcementDetails[] = [];
  schoolRoomId: any = null;
  gradeId: any = null;
  studentName: any = 'loading...';

  reminders: any[] = [];
  reminder = [];
  displayedColumns: string[] = ['name', 'repeat', 'expectation', 'action'];

  constructor(
    public dialogRef: MatDialogRef<AddReminderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private remindersService: RemindersService,
    private profileService: ProfileService,
    private toastr: ToastrService
  ) {
    this.student = this.data.student;
    this.expectations = this.data.expectations;
    this.schoolRoomId = this.data.schoolRoomId;
    this.gradeId = this.data.gradeId;
  }

  ngOnInit(): void {
    console.log(this.expectations, 'my expte')
    this.staffId = this.profileService.identity.userIdRole;
    const query = {
      staffId: this.staffId,
      schoolRoomId: this.schoolRoomId,
      gradeId: this.gradeId,
      studentId: this.student.studentId
    };
    this.remindersService.getRemindersByStudent(query).subscribe((reminders) => {
      console.log(reminders)
      if (reminders.length > 0) {
        this.reminder.push(reminders[0]);
        this.expectations.forEach((element: any) => {
          if (element.id === this.reminder[0].expectationStaffId) {
            this.reminder[0].name = element.name;
          }
        });
        this.play = !this.reminder[0].enabled;
        console.log('here- Init', this.reminder);
      }
    });
    console.log(this.data, 'my data')
    this.studentName = `${this.data.student.firstName} ${this.data.student.lastName}`
  }


  addReminder() {
    if (this.rExpectation === 'Please select the expectation') {
      this.toastr.error('Please select the expectation');
      return;
    }
    const request = {
      expectationStaffId: this.rExpectation.id,
      staffId: this.staffId,
      schoolRoomId: this.schoolRoomId,
      gradeId: this.gradeId,
      repeatInMinutes: this.rMinutes,
      studentId: this.student.studentId,
      name: this.rExpectation.name
    };
    this.remindersService.createReminder(request).subscribe((reminder) => {
      this.student.reminderActive = true;
      this.student.startTime = new Date().getTime();
      this.student.leftTime = parseInt(reminder.repeatInMinutes) * 60;
      this.student.reminderLoop = parseInt(reminder.repeatInMinutes) * 60;
      this.student.reminderId = `${this.student.studentId}_${this.schoolRoomId}_${this.gradeId}`;
      if (reminder) {
        reminder.timeout = parseInt(reminder.repeatInMinutes) * 60000;
        let reminder2: any = {
          ...reminder,
          studentName: this.student.firstName + ' ' + this.student.lastName,
          firstName: this.student.firstName,
          lastName: this.student.lastName
        };

        this.remindersService.addReminderNotification(reminder2);
        this.reminders.push(reminder);
        this.toastr.success(`Reminder for ${this.studentName} was successfully created`)
        this.onClose();
      }
    });

  }
  pauseReminder(reminder) {
    this.play = !this.play;

    this.remindersService.pause(reminder).subscribe((response) => {
      console.log('here- pauseReminder()', response);
      this.remindersService.removeReminderNotification(response);
      this.student.reminderActive = null;
      this.toastr.info(`Reminder for ${this.studentName} was successfully paused`)
    });
  }
  startReminder(reminder) {
    this.play = !this.play;
    let reminderName = this.expectations.find(c=>c.id == this.reminder[0].expectationStaffId).name;
    this.remindersService.start(reminder).subscribe((response) => {
      console.log('here- startReminder()', response);
      let response2 = {
        ...response,
        name: reminderName,
        studentName: this.student.firstName + ' ' + this.student.lastName,
        firstName: this.student.firstName,
        lastName: this.student.lastName
      };
      console.log('here- Response2', response2);
      this.remindersService.addReminderNotification(response2);
      this.student.reminderActive = true;
      this.student.startTime = new Date().getTime();
      this.student.leftTime = parseInt(reminder.repeatInMinutes) * 60;
      this.student.reminderLoop = parseInt(reminder.repeatInMinutes) * 60;
      this.student.reminderId = `${this.student.studentId}_${this.schoolRoomId}_${this.gradeId}`;
      this.toastr.info(`Reminder for ${this.studentName} was successfully resumed`)
    });
  }
  removeReminder(reminder) {
    const query = {
      staffId: this.staffId,
      schoolRoomId: this.schoolRoomId,
      gradeId: this.gradeId,
      studentId: this.student.studentId
    };

    this.remindersService.removeReminder(reminder.id).subscribe((reminder) => {
      this.student.reminderActive = false;
      this.remindersService.removeReminderNotification(query as any);
      this.remindersService.getRemindersByStudent(query).subscribe((reminders) => {
        this.reminders = reminders;
      });
    });
  }
  onClose() {
    this.dialogRef.close();
  }

}
