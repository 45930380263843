import { ProfileService } from "./../../services/profile.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ChatService } from "../../services/chat.service";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { MailboxContactsDialogComponent } from "./mailbox-contacts-dialog/mailbox-contacts-dialog.component";
import { RoleServiceFactoryService } from "src/app/shared/services/role-service-factory.service";
import * as m from "moment";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-mailbox-menu-widget",
  templateUrl: "./mailbox-menu-widget.component.html",
  styleUrls: ["./mailbox-menu-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailboxMenuWidgetComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() showDialog: boolean;
  @Output() hideDialog = new EventEmitter();
  mailboxMenuCollapsed = false;
  loaded: boolean;
  searchKey: any;

  isLoading = false;

  mail = {
    cc: false,
    bcc: false,
  };
  // Mailbox editr initial content
  content = "<p>Type something..</p>";
  conversations: any = [];
  conversationList: any = [];
  contacts: any = [];
  contactsFilter: any = [];
  contactsReceivedSubscription: Subscription;
  inboxChangedSubscription: Subscription;
  userId: string = null;
  me: string;

  constructor(
    public chatService: ChatService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    public dialog: MatDialog,
    public rs: RoleServiceFactoryService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loaded = true;
    this.isLoading = true;

    this.userId = this.profileService.identity.userIdRole;
    this.chatService.contactsReceived.subscribe((contacts) => {
      this.contacts = contacts;
    });
    this.chatService.loadConversations();
    this.chatService.allConversations.subscribe((conversations) => {
      this.isLoading = false;
      if (conversations?.length > 0) {
        this.conversations = conversations.map((c) => this.filterReceiver(c));
        this.conversationList = JSON.parse(JSON.stringify(this.conversations));
        this.conversations.forEach((element) => {
          element.isLoaded = false;
          this.chatService.chatCache[element.id] = element;
          element.lastUpdateHuman = this.getTimeSince(element.lastUpdate);
        });
        this.conversations = _.orderBy(
          this.conversations,
          ["lastUpdate"],
          ["desc"]
        );
        this.chatService.conversationsReceived.next();
        this.cd.markForCheck();
      }
    }, error => {
      this.isLoading = false;
      this.toastr.error(`Can't load Conversations`);
    });

    this.inboxChangedSubscription = this.chatService.inboxChanged.subscribe(
      (conversations) => {
        this.conversationList = JSON.parse(JSON.stringify(this.conversations));
        this.chatService.chatCache = conversations.conversations;
        if (conversations.message) {
          var id = conversations.message[0].conversationId;
          this.router.navigate(["folder", id], { relativeTo: this.route });
        }
      }
    );
    this.chatService.menuNotificationReceived$.subscribe((message) => {
      let conversationId = message.conversationId;
      var conversationIndex = this.conversations.findIndex(
        (c) => c.id == conversationId
      );
      this.conversations[conversationIndex].lastUpdate = m().toISOString();
      this.conversations[conversationIndex].lastUpdateHuman = this.getTimeSince(
        this.conversations[conversationIndex].lastUpdate
      );
      this.conversations = _.orderBy(
        this.conversations,
        ["lastUpdate"],
        ["desc"]
      );
      localStorage["conversations"] = this.conversations.map((c) => c.id);
      this.cd.markForCheck();
    });
  }
  ngOnChanges() {
    if (this.showDialog) {
      this.openDialog();
    }
  }

  getUsers(participants, me) {
    let you: string;
    let p1: string;
    let p2: string;
    if (participants.length == 1) {
      you = participants[0].username;
      return you;
    }
    if (participants.length == 2) {
      p1 = participants[0].username;
      p2 = participants[1].username;
      if (p1 == me) {
        you = p2;
        return you;
      } else {
        you = p1;
        return you;
      }
    }
  }

  getTimeSince(time) {
    let timeSince = m(time).fromNow();
    return timeSince;
  }

  startChat(contact: any) {
    var conversation = Object.keys(this.chatService.chatCache).find(
      (c) =>
        this.chatService.chatCache[c].participants?.find(
          (p) => p.username == contact.empCode
        ) != null
    );
    if (conversation) {
      this.router.navigate(["folder", conversation], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(["compose", contact.empCode], {
        relativeTo: this.route,
      });
    }
  }
  addMessageToConversation(msg) {
    this.conversations[msg.conversationId].messages.push(msg.message);
    this.conversationList[msg.conversationId].messages.push(msg.message);
  }
  routeIsActive(routePath: string) {
    return this.router.url == routePath;
  }
  filterReceiver(conversation) {
    let username = this.profileService.identity.sub;
    conversation.participants = conversation.participants.filter(
      (p) => p.username != username
    );
    return conversation;
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(MailboxContactsDialogComponent, {
      data: { contact_list: this.contacts },
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.hideDialog.emit(false);
      if (result) {
        this.startChat(result);
      } else {
        console.log("The dialog was closed");
      }
    });
  }
  ngOnDestroy() {
    if (this.contactsReceivedSubscription) {
      this.contactsReceivedSubscription.unsubscribe();
    }
    if (this.inboxChangedSubscription) {
      this.inboxChangedSubscription.unsubscribe();
    }
  }
}
