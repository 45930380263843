import { catchError } from 'rxjs/operators';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AccomplishmentService } from "./../../services/accomplishment.service";
import { ExpectationsService } from "./../../services/expectations.service";
import { EventEmitter } from "@angular/core";
import { CertificateService } from "src/app/shared/services/certificate.service";
import { StudentPbisService } from "./../../services/student-pbis.service";
import { BusService } from "./../../services/bus.service";
import { Component, Input, OnInit, AfterViewInit, Output } from "@angular/core";
import { CalendarService } from "../../services/calendar.service";
import { StudentsService } from "../../services/students.service";
import { ProfileService } from "../../services/profile.service";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM";
import * as olProj from "ol/proj";
import TileLayer from "ol/layer/Tile";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import * as m from "moment";
import { StaffFilesService } from "../../services/staff-files.service";
import { forEach } from "lodash";
import { ChecklistService } from "../../services/checklist.service";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: "app-timeline-widget",
  templateUrl: "./timeline-widget.component.html",
  styleUrls: ["./timeline-widget.component.scss"],
})
export class TimelineWidgetComponent implements OnInit, AfterViewInit {
  _m = m;
  constructor(
    private router: Router,
    private studentServices: StudentsService,
    private calendarServices: CalendarService,
    private busService: BusService,
    private pbisService: StudentPbisService,
    private certificateServices: CertificateService,
    public rs: RoleServiceFactoryService,
    private activeRoute: ActivatedRoute,
    private profileService: ProfileService,
    private expectationService: ExpectationsService,
    private staffFilesServices: StaffFilesService,
    private accomplishmentService: AccomplishmentService,
    private checklistService: ChecklistService,
    private toastr: ToastrService
  ) {}

  lat = 33.790807;
  lng = -117.835734;
  zoom = 14;
  scrollwheel = false;
  studentId: any;
  transportationKey: any;
  attendanceKey: any;
  comments: any = [];
  userRole: any;
  @Input() assignments: any = [];
  @Output() command: EventEmitter<any> = new EventEmitter<any>();
  showPBIS = false;

  timelineEvents = new Array();

  get sortedEvents() {
    return _.orderBy(this.events, ["created"], ["desc"]).filter((e) =>
      m(e.created).isBefore()
    );
  }


  get todayEvents() {
    const _events = [];
    this.sortedEvents.forEach((x) => {
      if (this.calculateHours(x.created) <= 24) {
        _events.push(x);
      }
    });
    return _events;
  }

  get thisWeekEvents() {
    const _events = [];
    this.sortedEvents.forEach((x) => {
      if (
        this.calculateHours(x.created) > 24 &&
        this.calculateDays(x.created) <= 7
      ) {
        _events.push(x);
      }
    });
    return _events;
  }

  get thisMonthEvents() {
    const _events = [];
    this.sortedEvents.forEach((x) => {
      if (
        this.calculateDays(x.created) > 7 &&
        this.calculateDays(x.created) <= 30
      ) {
        _events.push(x);
      }
    });
    return _events;
  }

  get earlierEvents() {
    const _events = [];
    this.sortedEvents.forEach((x) => {
      if (this.calculateDays(x.created) > 30) {
        _events.push(x);
      }
    });
    return _events;
  }
  events = [];

  showEvents = [];
  maps = [];
  mapEnabled = false;
  pbisData = null;
  settings = null;

  isLoading = false;

  loadTimeline(){

    var assignmentsCall = this.studentServices.getStudentAssignmentsByStudentId(this.studentId)
    var attendanceCall = this.studentServices.getStudentAttendace(this.studentId);
    var pbisCall = this.pbisService.getPbis(this.studentId);
    var checklistCall = this.checklistService.timeline(this.studentId);
    var accomplishmentsCall = this.accomplishmentService.timeline(this.studentId);
    var settings = this.certificateServices.getThumbnails();


    const timeline$ = forkJoin({
      assignments: assignmentsCall,
      attendance: attendanceCall,
      pbis: pbisCall,
      checklist: checklistCall,
      accomplishments: accomplishmentsCall,
      settings: settings
    });

    timeline$.subscribe(
      (response)=>{
        this.settings = response.settings;
        var timelineEvents = new Array();
        var events = null;
        if(!(response.assignments instanceof HttpErrorResponse)){
          events = this.processAssignments(response.assignments);
          timelineEvents.push(...events);
        }
        if(!(response.attendance instanceof HttpErrorResponse)){
          events = this.processAttendance(response.attendance);
          timelineEvents.push(...events)
        }

        if(!(response.pbis instanceof HttpErrorResponse)){
          events = this.processPbis(response.pbis)
          timelineEvents.push(...events);
        }

        if(!(response.checklist instanceof HttpErrorResponse)){
          events = this.processChecklist(response.checklist);
          timelineEvents.push(...events);
        }

        if(!(response.accomplishments instanceof HttpErrorResponse)){
          events = this.processAccomplishment(response.accomplishments);
          timelineEvents.push(...events)
        }

        return this.sortEvents(timelineEvents);
      });
  }

  nowEvents = new Array();
  weekEvents = new Array();
  monthEvents = new Array();
  priorEvents = new Array();
  timeFrames = new Array();


  sortEvents(timeline){

    let today = m().seconds(0).minutes(0).hours(0).toDate();
    let week = m().seconds(0).minutes(0).hours(0).days(-7).toDate();
    let month = m().seconds(0).minutes(0).hours(0).days(-30).toDate();

    _.each(timeline,(evt)=>{
      if(evt.created > today) {
        this.nowEvents.push(evt);
      } else if(evt.created > week){
        this.weekEvents.push(evt);
      } else if(evt.created > month){
        this.monthEvents.push(evt);
      } else {
        this.priorEvents.push(evt);
      }
    });

    this.timeFrames.push({name:'Today', events: _.orderBy(this.nowEvents,['created'],['desc'])})
    this.timeFrames.push({name:'This Week', events:  _.orderBy(this.weekEvents,['created'],['desc'])})
    this.timeFrames.push({name:'This Month', events:  _.orderBy(this.monthEvents,['created'],['desc'])})
    this.timeFrames.push({name:'Earlier', events:  _.orderBy(this.priorEvents,['created'],['desc'])})
  }
  processAssignments(assignments){
    let tasks = new Array();
    assignments.section.forEach((a) => {
      tasks = tasks.concat(a.assignments.assignment.map((a)=>{
        a.created = m(a.due).toDate();
        a.data = a;
        return a;
      }));
    });
    return tasks.filter((t) => t.due !== '');
  }
  processAttendance(attendance){
    var events = new Array();
    if (attendance.termList) {
      if (attendance.termList[0]) {
        _.each(attendance.termList[0].courseList, (course) => {
          if (course.absence != "0") {
            _.each(course.daysAbsent, (day) => {
              day.courseId = course.courseId;
              events.push({
                type: "attendance-absent",
                id: null,
                title: "Absent",
                data: course,
                created: day.reasonDate,
                ref: day,
                sourceOwner: day.instructorEmployeeId,
              });
            });
          }
          if (course.tardy != "0") {
            _.each(course.daysAbsent, (day) => {
              events.push({
                type: "attendance-tardy",
                id: null,
                title: "Tardy",
                data: course,
                created: day.reasonDate,
                ref: day,
                sourceOwner: day.instructorEmployeeId,
              });
            });
          }
        });
      }
    }
    return events;
  }
  processPbis(pbisData){
    var events = new Array();
    pbisData.files.forEach((element) => {
      events.push({
        type: "file",
        data: element,
        created: m(element.created),
        visible: false,
        sourceOwner: element.staffId,
      });
    });
    pbisData.certificates.forEach((element) => {
      events.push({
        type: "certificate",
        data: element,
        created: m(element.created),
        visible: false,
        sourceOwner: element.staffId,
      });
    });
    pbisData.surveys.forEach((element) => {
      events.push({
        type: "survey",
        data: element,
        created: m(element.created),
        visible: false,
        sourceOwner: element.staffId,
      });
    });
    pbisData.points.forEach((element) => {
      events.push({
        type: "expectation",
        data: element,
        created: m(element.created),
        visible: false,
        sourceOwner: element.staffId,
      });
    });
    return events;
  }
  processChecklist(result){
    var events = new Array();
    _.forEach(result, (row) => {
      // var card = this.accomplishmentMessage(row)
      events.push({
        type: "checklist",
        data: row,
        //  cardBody: card?.cardBody,
        // cardTitle: card?.cardTitle,
        created: m(row.created),
        visible: false,
        sourceOwner: row.staffId,
      });
    });
    return events;
  }
  processAccomplishment(result){
    var events = new Array();
    _.forEach(result, (row) => {
      var card = this.accomplishmentMessage(row);
      events.push({
        type: "accomplishment",
        data: row,
        cardBody: card?.cardBody,
        cardTitle: card?.cardTitle,
        created: m(row.created),
        visible: false,
        sourceOwner: row.staffId,
      });
    });
    return events;

  }
  ngOnInit(): void {

    this.userRole = this.profileService.profile.role;
    this.activeRoute.parent.params.subscribe((data) => {

      this.studentId = data.studentId;
      this.loadTimeline();

      this.transportationKey = `${this.studentId}_transportation`;
      this.attendanceKey = `${this.studentId}_attendance`;
      return;
    });
  }

  accomplishmentMessage(row) {
    if (row.accomplishmentCategory == "Awards")
      return {
        cardTitle: `Awards/Honors  Update`,
        cardBody: `has added an item to their Awards/Honors - ${row.name}`,
      };
    if (row.accomplishmentCategory == "EdPrograms")
      return {
        cardTitle: `Ed. Programs Update `,
        cardBody: `has added an item to their Ed. Programs - ${row.organization}`,
      };
    if (row.accomplishmentCategory == "ExtraCurricular")
      return {
        cardTitle: `Extra Curricular Update`,
        cardBody: `has added an item to their Extra Curricular - ${row.activity}`,
      };
    if (row.accomplishmentCategory == "CollegeClasses")
      return {
        cardTitle: `College Classes Update`,
        cardBody: `has added an item to their College Classes - ${row.school}`,
      };
    if (row.accomplishmentCategory == "WorkExp")
      return {
        cardTitle: `Work Experience Update `,
        cardBody: `has added an item to their Work Exp. - ${row.organization}`,
      };
    if (row.accomplishmentCategory == "Volunteer")
      return {
        cardTitle: `Volunteer Update`,
        cardBody: `has added an item to their Volunteer - ${row.organization}`,
      };
  }

  schoologyId(link: string) {
    return `${this.studentId}_${link.substr(link.lastIndexOf("/") + 1)}`;
  }
  attendanceId(link: any) {
    return `${this.studentId}_${link.courseId}_${link.reasonDate
      .replace("/", "_")
      .replace("/", "_")}`;
  }

  startMap(mapData) {
    this.mapEnabled = true;
    setTimeout(() => {
      const lon = mapData.tracking[0].longitude;
      const lat = mapData.tracking[0].latitude;
      const map = new Map({
        target: mapData.mapId,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: olProj.fromLonLat([lon, lat]),
          zoom: 10,
        }),
      });
      this.busService.addBus(
        mapData.tracking[0].latitude,
        mapData.tracking[0].longitude,
        map
      );
    }, 700);
  }
  loadCardDetails(ev) {
    ev.visible = true;
    if (ev.type == "expectation") {
      this.expectationService
        .getStaffExpectation(ev.data.staffId, ev.data.expectationId)
        .toPromise()
        .then((expectation) => {
          ev.expectation = expectation;
        });
    }
    if (ev.type == "file") {
      this.staffFilesServices
        .getDownloadLink(ev.data.staffId, ev.data.id)
        .subscribe((url) => {
          if (url) {
            const re = /(?:\.([^.]+))?$/;
            ev.data.fileType = re.exec(url.filename)[1];
            let imgType: boolean = false;

            switch (ev.data.fileType) {
              case "png":
                ev.data.img = url.previewLink;
                imgType = true;
              case "jpg":
                ev.data.img = url.previewLink;
                imgType = true;
              case "jpeg":
                ev.data.img = url.previewLink;
                imgType = true;
              case "gif":
                ev.data.img = url.previewLink;
                imgType = true;
              case "webm":
                ev.data.img = url.previewLink;
                imgType = true;
              case "svg":
                ev.data.img = url.previewLink;
                imgType = true;
              case "tiff":
                ev.data.img = url.previewLink;
                imgType = true;
              case "tif":
                ev.data.img = url.previewLink;
                imgType = true;
            }

            if (imgType == false) {
              ev.data.img = null;
            }
          }
        });
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      for (const item in this.maps) {
        const idMap = this.maps[item];
        this.startMap(idMap);
      }
    }, 5000);
  }

  pbisModeChange() {
    this.showPBIS = !this.showPBIS;
  }

  calculateHours(time) {
    return m().diff(m(time), "hours", true);
  }

  calculateDays(time) {
    return m().diff(m(time), "days", true);
  }

  download(data) {
    this.command.emit({
      cmd: "download-file",
      params: {
        studentId: this.studentId,
        id: data.id,
      },
    });
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
