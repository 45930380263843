import { SchoolRoomService } from './../../services/school-room.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ClassDetails, ClassAction } from './models/ClassDetails';

@Component({
  selector: 'app-class-details-widget',
  templateUrl: './class-details-widget.component.html',
  styleUrls: ['./class-details-widget.component.scss']
})
export class ClassDetailsWidgetComponent implements OnInit {

  @Input() class: ClassDetails = null;
  @Output() onAction = new EventEmitter<ClassAction>();

  constructor(
    private router: Router,
    private schoolRoomServices: SchoolRoomService) { }

  ngOnInit(): void {
    // this.schoolRoomServices.getSchoolRoomDetailsById(this.class.id,this.class.grade).subscribe((schoolRoomDetails)=>{
    //   this.class.points = schoolRoomDetails.points;
    // })
  }

  onClickCard() {
    this.schoolRoomServices.setActiveSchoolRoom(this.class);
    this.router.navigate(['/teacher/classes/', this.class.id, 'grade', this.class.grade]);
  }

  onManageGroup(selectedClass: ClassDetails) {
    const action: ClassAction = {
      target: selectedClass,
      command: 'manageGroup'
    };
    this.onAction.emit(action);
  }

  onGiveReinforcement(selectedClass: ClassDetails) {
    const action: ClassAction = {
      target: selectedClass,
      command: 'giveReinforcement'
    };
    this.onAction.emit(action);
  }

}
