import { ProfileService } from "./services/profile.service";
import { RoleServiceFactoryService } from "./services/role-service-factory.service";
import { StaffSettingsService } from "src/app/shared/services/staff-settings.service";
import { Injectable } from "@angular/core";
import { Observable, Subject, Subscription, timer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BadgeStatusService {
  _status = [];
  _lockStatus = [];
  badgeStatusChanged: Subject<any>;
  _badgeSubscription = [];
  _queue = [];

  cron = timer(1000, 10000);
  timerSubscription: Subscription;
  constructor(
    private staffSettings: StaffSettingsService  ) {
    this.timerSubscription = this.cron.subscribe(()=>this.checkStatus());
  }

  public status: any = [];

  trackStatus(userId: string): Observable<any> {
    if (!this._badgeSubscription[userId])
      this._badgeSubscription[userId] = new Subject<any>();
    this.enqueue(userId);
    return this._badgeSubscription[userId];
  }

  enqueue(userId: string) {

    if(this._queue.find((i)=>i==userId) == undefined)
      this._queue.push(userId);
  }
  checkStatus(){
    for (var i = 0; i < 5; i++) {
      if (this._queue.length > 0) {
        var userId = this._queue.pop();
        this.staffSettings
          .silentSettings(userId)
          .toPromise()
          .then((response) => {
            let subject = this._badgeSubscription[userId] as Subject<any>;
            if (subject) {
              subject.next(response);
            }
          });
      }
    }
  };
  ngOnDestroy() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }
}
