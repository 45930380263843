import { ProfileService } from "./../../shared/services/profile.service";
import { Router } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import { ContactsModel } from "./../../routes/parent/contacts-route/contacts/model/ContactsModel";
import { ContactsService } from "./../../shared/services/contacts.service";
import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core";

import { SettingsService } from "../../core/settings/settings.service";
import { ThemesService } from "../../core/themes/themes.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { ContactModel } from "src/app/shared/components/contact-widget/models/ContactWidgetModel";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ContactProfileComponent } from "src/app/routes/student/contacts-route/contact-profile/contact-profile.component";
import { TeacherService } from "src/app/shared/services/teacher.service";
import * as _ from "lodash";
import { NotificationServices } from "../../shared/services/notification.service";
import { EventEmitter } from "events";
import { ModalService } from "src/app/shared/services/modal.service";
import { ContactDetailsWidgetComponent } from "src/app/shared/components/contact-details-widget/contact-details-widget.component";
import { has } from "lodash";

@Component({
  selector: "app-offsidebar",
  templateUrl: "./offsidebar.component.html",
  styleUrls: ["./offsidebar.component.scss"],
})
export class OffsidebarComponent implements OnInit, OnDestroy {
  selectedContact: any;
  currentTheme: any;
  duplicateContactData: any;
  studentData: any;
  byRoleFilter: any;
  getStudentByFilter: any;
  selectedLanguage: string;
  role: string;
  contacts: any = [];
  images = [];
  staffId = null;
  userProfile: any;
  searchKey: any = "";
  tour: boolean = true;

  classList: any = [];
  roleList: any = [];
  studentList: any = [];

  _schoolRoomList: any[];
  _gradeList: any[];
  _schoolRoomFiltered: any[];
  _schoolList: any[];

  constructor(
    public settings2: SettingsService,
    public themes: ThemesService,
    public translator: TranslatorService,
    public elem: ElementRef,
    public contactsService: ContactsService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private dialog: MatDialog
  ) {
    this.currentTheme = themes.getDefaultTheme();
  }

  ngOnInit() {
    this.getStudentByFilter = "";
    this.byRoleFilter = "";
    this.role = this.profileService.role.toLowerCase();
    this.staffId = this.profileService.identity.userIdRole;
    this.userProfile = this.profileService.profile;
    this.tour = this.userProfile.hasTour;
    this.anyClickClose();

    this.selectedLanguage = this.userProfile.language
      ? this.userProfile.language
      : this.getLangs()[0].code;

    // this.contactsService.getContacts().subscribe((contacts) => {
    //   this.contacts = contacts;
    //   this.duplicateContactData = JSON.parse(JSON.stringify(this.contacts));
    //   this.studentData = this.duplicateContactData.filter((val: any) => {
    //     return val.profile === null;
    //   });
    //   this.getClassList();
    //   this.getStudentList();
    //   this.getRoleList();
    // });
  }

  toggleTour(tour) {
    this.userProfile.hasTour = !tour;
    this.userProfile.themeSettings = JSON.parse(localStorage.getItem("theme"));
    this.profileService.saveProfile(this.userProfile).subscribe(() => {
      this.tour = !tour;
      this.profileService.getProfile().subscribe(() => {
        this.notificationServices.showSuccessToast(
          "Your profile has been updated"
        );
        //window.location.reload();
      });
    });
  }

  contactDetails(contactId) {
    this.profileService.getProfileById(contactId).subscribe((contact) => {
      this.selectedContact = contact;
      const dialogRef = this.dialog.open(ContactDetailsWidgetComponent, {
        data: this.selectedContact,
      });
    });
  }

  getStudentList() {
    let idList = [];
    this.duplicateContactData.forEach((contact) => {
      if (contact.studentDistrictId.length > 0) {
        let model = {
          studentId: contact.studentDistrictId[0],
        };
        idList.push(model);
      }
    });
    idList = _.uniqBy(idList, "studentId");
    idList.forEach((student) => {
      this.profileService
        .getProfileById(student.studentId)
        .subscribe((profile) => {
          student.firstName = profile.firstName;
          student.lastName = profile.lastName;
        });
    });
    this.studentList = idList;
  }

  getRoleList() {
    this.duplicateContactData.forEach((contact) => {
      if (contact.role) {
        let model = {
          role: contact.role,
        };
        this.roleList.push(model);
      }
    });
    this.roleList = _.uniqBy(this.roleList, "role");
  }

  getClassList() {
    this.duplicateContactData.forEach((contact) => {
      if (contact.subject) {
        let model = {
          subject: contact.subject,
        };
        this.classList.push(model);
      }
    });
    this.classList = _.uniqBy(
      _.orderBy(this.classList, ["subject"], ["asc"]),
      "subject"
    );
  }

  anyClickClose() {
    document.addEventListener("click", this.checkCloseOffsidebar, false);
  }

  checkCloseOffsidebar = (e) => {
    const contains =
      this.elem.nativeElement !== e.target &&
      this.elem.nativeElement.contains(e.target);
    if (!contains) {
      this.settings2.setLayoutSetting("offsidebarOpen", false);
    }
  };
  ngOnDestroy() {
    document.removeEventListener("click", this.checkCloseOffsidebar);
  }

  resetFilters() {
    this.contacts = this.duplicateContactData;
  }

  contactFilterByRole(role) {
    if (role === "") {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.role === null;
      });
    } else {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.role == role;
      });
    }
  }

  contactFilterByStudent(student) {
    if (student === "") {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.profile === null;
      });
    } else {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.studentDistrictId[0] == student;
      });
    }
  }

  contactFilterByClass(subject) {
    if (subject === "") {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.profile === null;
      });
    } else {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.subject == subject;
      });
    }
  }
  contactFilterByGrade(grade) {
    if (grade === "") {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.profile === null;
      });
    } else {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.gradeId == grade;
      });
    }
  }

  setTheme() {
    this.themes.setTheme(this.currentTheme);
  }

  resetDefaultTheme() {
    this.themes.resetDefaultTheme();
  }

  getImage(model) {
    return model.role == "Principal"
      ? "/assets/img/icons/teacher.svg"
      : model.role == "Student"
      ? "/assets/img/icons/student.svg"
      : "/assets/img/icons/principal.svg";
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.lastLng = this.userProfile.language;
    this.userProfile.language = value;
    this.userProfile.themeSettings = JSON.parse(localStorage.getItem("theme"));
    this.profileService.saveProfile(this.userProfile).subscribe(() => {
      this.profileService.getProfile().subscribe(() => {
        this.notificationServices.showSuccessToast(
          "Your profile has been updated"
        );
        this.translator.processTranslate();
        window.location.reload();
      });
    });
  }

  // contactFilterByStudent(studentVal) {
  //   this.byRoleFilter = "";
  //   if (studentVal) {
  //     this.contacts = this.duplicateContactData.filter((val: any) => {
  //       if (val.studentDistrictId.length) {
  //         const getStudent = val.studentDistrictId.filter((vals) => {
  //           return vals === studentVal;
  //         });
  //         return getStudent.length;
  //       } else {
  //         return false;
  //       }
  //     });
  //   } else {
  //     this.contacts = this.duplicateContactData;
  //   }
  // }

  contactFilterByCategory(evt: any) {
    this.getStudentByFilter = "";

    if (evt === "by_teacher") {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.profile === "employee";
      });
    } else if (evt === "by_student") {
      this.contacts = this.duplicateContactData.filter((val: any) => {
        return val.profile === null;
      });
    } else {
      this.contacts = this.duplicateContactData;
    }
  }
}
