import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import * as m from 'moment';
import { OperationsService } from '../../services/operations.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-container-card-widget',
  templateUrl: './container-card-widget.component.html',
  styleUrls: ['./container-card-widget.component.scss']
})
export class ContainerCardWidgetComponent implements OnInit {

  @Input() report: string;
  @Input() lastUpdated: any;
  @Input() totalScore: string;
  reportTitle: string;
  reportImg: string;
  reportImgAlt: string;
  role: any;

  hasHelpLink: boolean = false;
  helpLink: any;
  anchor: any = '';

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private operationsService: OperationsService
  ) { }

  ngOnInit(): void {
    this.role = this.profileService.role;
    this.getHasHelpLink();
    this.applyReport(this.report);
  }

  getHasHelpLink() {
    if(this.role == 'Parent'){
      this.hasHelpLink = true;
    }
    if(this.role == 'Student'){
      this.hasHelpLink = true;
    }
  }

  goToHelp(){
    // this.router.navigate([this.role.toLocaleLowerCase(), `help`])
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([`${this.role.toLocaleLowerCase()}/help`], { fragment: this.anchor }).finally(() => {
        this.router.onSameUrlNavigation = "ignore"; // Restore config after navigation completes
    });
  }

  applyReport(report){
    switch (report) {
      case 'assignments':
        this.reportTitle = 'Assignments & Assessments';
        this.reportImg = '/assets/img/icons/studentProfile/grades.png'
        this.reportImgAlt = this.reportTitle;
        this.getUpdatedDmsTime('daily');
        break;
      case 'attendance':
        this.reportTitle = 'Attendance';
        this.reportImg = '/assets/img/icons/studentProfile/attendance.png'
        this.reportImgAlt = this.reportTitle;
        this.getUpdatedDmsTime('attendance');
        break;
      case 'audience':
        this.reportTitle = 'Audience';
        this.reportImg = '/assets/img/icons/studentProfile/attendance.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'bus routes':
        this.reportTitle = 'Bus Routes';
        this.reportImg = '/assets/img/icons/studentProfile/transportation.png'
        this.reportImgAlt = this.reportTitle;
        this.anchor = "Transportation"
        break;
      case 'ccrg':
          this.reportTitle = 'College & Career';
          this.reportImg = '/assets/img/icons/studentProfile/ccrg.png'
          this.reportImgAlt = this.reportTitle;
          break;
      case 'certificates':
        this.reportTitle = 'Certificates';
        this.reportImg = '/assets/img/icons/studentProfile/Certificate.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'checklist':
          this.reportTitle = 'Checklist';
          this.reportImg = '/assets/img/icons/studentProfile/checklist.svg'
          this.reportImgAlt = this.reportTitle;
          break;
      case 'suspension':
        this.reportTitle = 'Discipline';
        this.reportImg = '/assets/img/icons/studentProfile/suspension.png'
        this.reportImgAlt = this.reportTitle;
        this.anchor = "Suspension";
        this.getUpdatedDmsTime('monthly')
        break;
      case 'emergency':
        this.reportTitle = 'Emergency Contacts';
        this.reportImg = '/assets/img/icons/studentProfile/emergency.png'
        this.reportImgAlt = this.reportTitle;
        this.getUpdatedDmsTime('daily')
        break;
      case 'elp':
        this.reportTitle = 'English Learner Progress';
        this.reportImg = '/assets/img/icons/studentProfile/esl.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'igp':
        this.reportTitle = 'Individualized Graduation Plan';
        this.reportImg = '/assets/img/icons/studentProfile/igp.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'expectations':
        this.reportTitle = 'Expectations';
        this.reportImg = '/assets/img/icons/studentProfile/Expectations.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'reinforcements':
        this.reportTitle = 'Expectations';
        this.reportImg = '/assets/img/icons/studentProfile/Expectations.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'files':
        this.reportTitle = 'Files';
        this.reportImg = '/assets/img/icons/studentProfile/Files.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'gate':
        this.reportTitle = 'Gifted/Talented Programs Information';
        this.reportImg = '/assets/img/icons/studentProfile/gate.png'
        this.reportImgAlt = this.reportTitle;
        this.anchor = "GATE Program";
        this.getUpdatedDmsTime('monthly')
        break;
      case 'grades':
        this.reportTitle = 'Grades';
        this.reportImg = '/assets/img/icons/studentProfile/grades.png'
        this.reportImgAlt = this.reportTitle;
        this.getUpdatedDmsTime('daily');
        break;
      case 'grad-report':
        this.reportTitle = 'Progress Towards Graduation';
        this.reportImg = '/assets/img/icons/studentProfile/graduation.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'immunization':
        this.reportTitle = 'Immunization';
        this.reportImg = '/assets/img/icons/studentProfile/immunization.png'
        this.reportImgAlt = this.reportTitle;
        this.anchor = "Immunization";
        this.getUpdatedDmsTime('monthly');
        break;
      case 'online forms':
        this.reportTitle = 'Online Forms';
        this.reportImg = '/assets/img/icons/studentProfile/onlineForms.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'report card':
        this.reportTitle = 'Report Card';
        this.reportImg = '/assets/img/icons/studentProfile/reportcard.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'report card':
        this.reportTitle = 'Report Card';
        this.reportImg = '/assets/img/icons/studentProfile/reportcard.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'special ed':
        this.reportTitle = 'Special Education';
        this.reportImg = '/assets/img/Portal_Sped_Logo.png'
        this.reportImgAlt = this.reportTitle;
        this.anchor = "Special Education"
        break;
      case 'surveys':
        this.reportTitle = 'Surveys';
        this.reportImg = '/assets/img/icons/studentProfile/Surveys.png'
        this.reportImgAlt = this.reportTitle;
        break;
      case 'testing':
        this.reportTitle = 'Standardized Test Scores';
        this.reportImg = '/assets/img/icons/studentProfile/testing.png'
        this.reportImgAlt = this.reportTitle;
        this.anchor = "CAASPP"
        break;
      case 'teacher comm groups':
        this.reportTitle = 'Communications';
        this.reportImg = '/assets/img/icons/studentProfile/comm-icon.png'
        this.reportImgAlt = this.reportTitle;
        break;
        case 'online library':
          this.reportTitle = 'Online Library';
          this.reportImg = '/assets/img/icons/studentProfile/onlinelibrary.png'
          this.reportImgAlt = this.reportTitle;
          break;
      default:
        break;
    }
  }

  getTimeSince(time) {
    let timeSince = m(time).fromNow();
    return 'Last updated ' +  timeSince
  }

  getUpdatedDmsTime(type: string){
    this.operationsService.getLastUpdate().subscribe(res => {


      switch (type) {
        case 'attendance':
          this.lastUpdated = m(res['dms-lausd-attendance'])
          break;
        case 'daily':
          this.lastUpdated = m(res['dms-lausd-daily'])
          break;
        case 'monthly':
          this.lastUpdated = m(res['dms-lausd-monthly'])
          break;
        case 'weekly':
          this.lastUpdated = m(res['dms-lausd-weekly'])
          break;
        default:
          break;
      }
    })
  }

}
