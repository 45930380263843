import { Injectable } from '@angular/core';
import * as m from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SchoolYearService {

  constructor() { }

  currentYear(){
    var currentMonth  = m().month();

    if(currentMonth >= 7)
      return `${m().year()}${m().year()+1}`;
    return`${m().year()-1}${m().year()}`;

  }
  currentSingleYear() {
    var currentMonth  = m().month();

    if(currentMonth >= 7)
      return `${m().year()}`;
    return`${m().year()-1}`;
  }
  lastYear() {
    var currentMonth  = m().month();

    if(currentMonth >= 7)
      return `${m().year()-1}${m().year()}`;
    return`${m().year()-2}${m().year()-1}`;
  }
}
