import { StudentsService } from 'src/app/shared/services/students.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as m from 'moment';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-student-graduation-report',
  templateUrl: './student-graduation-report.component.html',
  styleUrls: ['./student-graduation-report.component.scss']
})
export class StudentGraduationReportComponent implements OnInit {

  studentId: any
  lastUpdated: any;

  gotReport: boolean = false;
  isDownloading: boolean = false;
  successEng: boolean = false;
  successSp: boolean = false;
  failEng: boolean = false;
  failSp: boolean = false;
  errorReport: boolean = false;
  englishReport: any = null;
  spanishReport: any = null;

  message: any = '';

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    public readonly tourService: TourService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.lastUpdated = m();
    });

    this.startTour();

  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([{
      anchorId: 'start-tour',
      content: 'You can view the latest graduation report here. Click on the link to download as PDF.',
      title: 'Graduation Report',
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }


  getReport() {

    this.isDownloading = true;
    this.message = 'Please wait while we download your report';
    this.studentService.getStudentGraduationReport(this.studentId).subscribe((report) => {
      this.englishReport = report.buffer;
      this.gotReport = true;
      this.successEng = true;
      this.message = 'Download was successful. Click to view.'
      if(this.englishReport.length < 1000) {
        this.successEng = false;
        this.failEng = true;
        this.message = 'Please check your internet connection and retry'
      }
    }, error => {this.failEng = true})
  }

  downloadReport(lang) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    if(lang == 'english'){
      var blob = this.blobPdfFromBase64String(this.englishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `graduation_progress_report_english.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }
    if(lang == 'spanish'){
      var blob = this.blobPdfFromBase64String(this.spanishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `graduation_progress_report_spanish.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }


  }
  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };
}
