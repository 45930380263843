import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {


  baseUrl: string = this.appConfig.configuration.environment.endPoints.communicationsServices
  constructor(
    private appConfig: AppConfigService,
    private api: HttpClient,
    private profileService: ProfileService) {

   }
   getAlerts() : Observable<any>{
    let userId = this.profileService.identity.userIdRole
    let path = `${this.baseUrl}/v1/alerts`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  dismissAsync(alertId: string) {
    let userId = this.profileService.identity.userIdRole
    let path = `${this.baseUrl}/v1/alerts/${alertId}/dismiss/${userId}`;
    return this.api.post(path,{}).pipe(map((response)=>response as any));
  }
}
