import { Component, OnInit, Inject, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AudienceService } from '../../services/audience.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-audience-list-widget',
  templateUrl: './audience-list-widget.component.html',
  styleUrls: ['./audience-list-widget.component.scss']
})
export class AudienceListWidgetComponent implements OnInit, AfterViewInit {

  isEngagement = false;
  searchKey: any;
  list: any[] = [];
  listArray: any[] = [];
  _customData = []
  audienceModal = true;
  private _categoryId: string;

  @Input() set customData(value: any) {
     this._customData = value;
     this.dataSource.data = this._customData;
  }

  get customData(): any {
      return this._customData;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableColumns = ['picture', 'name', 'type'];
  dataSource = new MatTableDataSource<any>();

  constructor(
    private audienceService: AudienceService,
    private router: Router,
    public dialogRef: MatDialogRef<AudienceListWidgetComponent>,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.list) {
      this.list = data.list;
    }
    if (data && data.isEngagement){
      this.isEngagement = data.isEngagement;
    }
  }

  parentNum = 0;
  studentNum = 0;
  ngOnInit(): void {
    const entity = this.data.entity;
    const entityId = this.data.entityId;
    if(this.list?.length > 0) {
      this.dataSource.data = this.list;
      this.parentNum = this.list.filter(c=>c.target == 'Parent').length;
      this.studentNum = this.list.filter(c=>c.target == 'Student').length;
      return;
    };
    this.audienceService.getAudience(entity, entityId).subscribe((response) => {
      this.list = response;
      this.dataSource.data = this.list;
      this.parentNum = this.list.filter(c=>c.target == 'Parent').length;
      this.studentNum = this.list.filter(c=>c.target == 'Student').length;
    });
  }
  onClose() {
    this.dialogRef.close();
  }

  goPrint() {
    this.dialogRef.close({
      list: this.list,
      print: true
    })

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

    applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
