import { StudentsService } from 'src/app/shared/services/students.service';
import { Observable } from 'rxjs/Observable';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsService } from '../../services/analytics.service';
import { ProfileService } from '../../services/profile.service';
import { ContactDetailsWidgetComponent } from '../contact-details-widget/contact-details-widget.component';
import swal from 'sweetalert';
import { ParentsService } from '../../services/parents.service';
import { ToastrService } from 'ngx-toastr';
import { SchoolService } from '../../services/school.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-student-contact-card',
  templateUrl: './student-contact-card.component.html',
  styleUrls: ['./student-contact-card.component.scss']
})
export class StudentContactCardComponent implements OnInit {

  @Input() student;
  @Input() parentId;

  @Output() changed = new EventEmitter<string>();
  userRole: any;
  schoolName
  $enrolmentStatus: Observable<string>

  constructor(
    private dialog: MatDialog,
    private profileService: ProfileService,
    private analyticsService: AnalyticsService,
    private parentService: ParentsService,
    private toastr: ToastrService,
    private schoolService: SchoolService,
    private studentService: StudentsService,
    private cdref: ChangeDetectorRef
  ) {
    this.userRole = profileService.profile.role;
  }

  ngOnInit(): void {
    this.schoolService.getSchoolsById([this.student.locationCode]).subscribe(res => {
      this.schoolName = res[0].schoolName;
      this.cdref.detectChanges();
    })
    this.studentService.getStudentById(this.student.studentId).subscribe((response)=>{
      var enrollment = response.enrollmentStatus;
      this.$enrolmentStatus = of(enrollment)
    });
  }

  ngOnDestroy() {
    this.cdref.detach();
  }

  openContactDetails(contact) {
    contact.fromEmContact = false;
    contact.name = `${contact.firstName}, ${contact.lastName}`;
    const dialogRef = this.dialog.open(ContactDetailsWidgetComponent, {
      data: contact,
    });
  }

  removeStudent(studentId) {
    this.analyticsService.sendEvent("User Click", "Remove Student");

    swal({
      title: `Remove student?`,
      icon: "warning",
      text: "Student will be removed from this parent account",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, remove",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        this.parentService.removeStudent2(studentId,this.parentId).subscribe((res) => {
          this.toastr.warning(`Successfully removed student`);
          this.changed.emit();
        });
      }
    });
  }

}
