import { CdnService } from "./../../../services/cdn.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { ModalService } from "./../../services/modal.service";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import AsyncComponent from "../common/AsyncComponent";
import { ISchoolInfo } from "../student-list-widget/models/ISchoolInfo";
import { IStudentResume } from "../student-list-widget/models/IStudentResume";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import * as _ from "lodash";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import { environment } from "src/environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { AppConfigService } from "src/app/services/app-config.service";
import { MatDialog } from "@angular/material/dialog";
import { RemoveStudentModalComponent } from "../remove-student-modal/remove-student-modal.component";



@Component({
  selector: "app-student-header-widget",
  templateUrl: "./student-header-widget.component.html",
  styleUrls: ["./student-header-widget.component.scss"],
})
export class StudentHeaderWidgetComponent implements OnInit, AsyncComponent {
  @Input() student: IStudentResume;
  @Input() schoolInfo: ISchoolInfo;
  // FIXME: not sure this one is necessary or not. do we have survey, cert etc in parent or student login mode?
  @Input() teacherLogin = false;
  @Input() role = "";
  @Input() hideMenu;

  // async component
  loaded = true;
  imgPath: string = null;
  self: StudentHeaderWidgetComponent = this;
  isHighSchool = false;
  studentName: string = null;
  firstName: string = null;
  middleName: string = null;
  lastName: string = null;
  schoolImg = null;
  schoolName = null;
  gradeDescription: String = "";
  userRole = "";

  get isProd(): boolean {
    let env = environment.environment_name;
    if(env === 'Development') return false
    else if(env === 'Stage') return false
    else return true
  }

  get isStaff() {
    if (this.profileService.role == "Admin") return true;
    if (this.profileService.role == "Counselor") return true;
    if (this.profileService.role == "Teacher") return true;
    else return false;
  }

  get showIgp() {
    return (
      parseInt(this.student.gradeCode) >= 7 &&
      parseInt(this.student.gradeCode) <= 12
    );
  }

  menuOpen: boolean = true;
  showRoute = false;

  token: any;
  studentId: any;
  email: any;
  action: any;
  lang = "en-us";
  ssoUrl: any;

  constructor(
    public rs: RoleServiceFactoryService,
    private ps: ProfileService,
    private router: Router,
    private parentServices: ParentsService,
    private studentService: StudentsService,
    private sanitize: DomSanitizer,
    private toastr: ToastrService,
    private modalServices: ModalService,
    private cdnService: CdnService,
    public tourService: TourService,
    private profileService: ProfileService,
    private oauthService: OAuthService,
    private appService: AppConfigService,
    private appConfig: AppConfigService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {}
  ngOnChanges(): void {
    console.log(this.isProd, 'config===')
    console.log(this.student);
    this.studentService
      .getStudentBusRoutes(this.student.studentId)
      .subscribe((response) => {
        if (response.routes != null) {
          this.showRoute = true;
        }
      });
    this.loaded = true;
    this.userRole = this.ps.role.toLowerCase();
    this.getGradeDesc(this.student);
    this.isHighSchool =
      parseInt(this.student.gradeCode) >= 9 &&
      parseInt(this.student.gradeCode) <= 12;
    // this.startTour();

    //get background img
    this.studentService
      .getStudentSchoolInfo(this.student.studentId)
      .subscribe((school) => {
        this.schoolName = school.schoolName;
        this.schoolImg = `linear-gradient(to top, rgba(47, 51, 201, 0.37), rgb(14, 44, 74)), url(${this.cdnService.schoolBackgrounds}${school.costCenterCode}.jpg), url('/assets/img/school-image-bg.png')`;
        this.schoolImg = this.sanitize.bypassSecurityTrustStyle(this.schoolImg);
      });

    //get full name
    this.studentService
      .getStudentById(this.student.studentId)
      .subscribe((student) => {
        this.firstName = student.firstName;
        if (student.middleInitial != null) {
          this.middleName = student.middleInitial;
        } else {
          this.middleName = "";
        }
        this.lastName = student.lastName;
        this.studentName =
          this.firstName + " " + this.middleName + " " + this.lastName;
      });
  }

  initMenu() {
    this.menuOpen = true;
    setTimeout(() => {
      this.menuOpen = false;
    }, 2000);
  }

  navToStudentPage() {
    if (this.router.url.includes("timeline")) {
      this.menuOpen = true;
    } else {
      this.menuOpen = true;
      setTimeout(() => {
        this.menuOpen = false;
      }, 2000);
    }
  }

  initFormValues() {
    this.studentId = this.student.studentId;
    this.email = this.profileService.profile.email;
    var accessToken = this.profileService.parseJwt(
      this.oauthService.getAccessToken()
    );
    this.token = accessToken["idcs-auth-token"];
  }

  uploadDocs() {
    this.initFormValues();
    this.ssoUrl = this.appService.configuration.environment.configuration.ppServices.externalLogin.enroll;

    this.action = "Uploads";
    setTimeout(() => {
      document.forms["stuEnr"].submit();
    }, 1000);
  }
  requestDevice() {
    this.initFormValues();
    this.ssoUrl = this.appService.configuration.environment.configuration.ppServices.externalLogin.myapps;
    this.action = "DeviceRequest";
    setTimeout(() => {
      document.forms["stuEnr"].submit();
    }, 1000);
  }
  goNaviance() {
    window.open("https://achieve.lausd.net/Page/15193", "_blank");
  }

  getGradeDesc(student) {
    switch (student?.gradeCode) {
      case "PK":
        this.gradeDescription = "PreKindergartener @";
      case "K":
        this.gradeDescription = "Kindergartener @";
      case "1":
        this.gradeDescription = "1st Grader Student @";
      case "2":
        this.gradeDescription = "2nd Grader Student @";
      case "3":
        this.gradeDescription = "3rd Grader Student @";
      default:
        this.gradeDescription = `${student.gradeCode}th Grader Student @`;
    }
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  // startTour() {
  //   let above: MdMenuPlacement = { yPosition: 'above' }
  //   let below: MdMenuPlacement = { yPosition: 'below' }
  //   let left: MdMenuPlacement = { xPosition: 'before' }
  //   let right: MdMenuPlacement = { xPosition: 'after' }

  //   this.tourService.initialize([{
  //     anchorId: 'start-tour',
  //     content: 'Here you will find access to all your information',
  //     title: 'Welcome to your Student Profile!',
  //   },
  //   ]);
  //   this.tourService.start();
  // }

  isParent() {
    return this.ps.role == "Parent";
  }
  isAdmin() {
    return this.ps.role == "Administrator";
  }
  studentImage(student: IStudentResume) {
    return `https://uifaces.co/our-content/donated/Te-0H20q.png`;
  }
  goSchoology() {
    this.parentServices
      .getSchoologyRemoteLogin("web", this.student.districtId)
      .subscribe((response) => {
        if(response.errorResponse.errorCode == '2804' || response.errorResponse.errorCode == '2802'){
          this.toastr.error(`${response.errorResponse.errorMessage} - Please contact the Help Desk at (213)241-5200`);
          return
        }
        if(response.remoteLoginURL){
          window.open(
            response.remoteLoginURL + "/parent/grades_attendance/grades"
          );
        }
      });
  }
  goMenu(){
    window.open("https://lausd.yumyummi.com/webapp");
  }

  getIgpReport() {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    this.studentService
      .getStudentPdfReport(this.student.studentId, "igp", "english")
      .subscribe((response) => {
        var blob = this.blobPdfFromBase64String(response.buffer);

        if (ie || oldIE || ieEDGE) {
          window.navigator.msSaveBlob(blob, `report_igp.pdf`);
        } else {
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      });
  }

  getGraduationReport() {
    this.studentService
      .getStudentGraduationReport(this.student.studentId)
      .subscribe((response) => {
        var blob = this.blobPdfFromBase64String(response.buffer);
        var ieEDGE = navigator.userAgent.match(/Edge/g);
        var ie = navigator.userAgent.match(/.NET/g); // IE 11+
        var oldIE = navigator.userAgent.match(/MSIE/g);

        if (ie || oldIE || ieEDGE) {
          window.navigator.msSaveBlob(blob, `report.pdf`);
        } else {
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
        //window.open(response.url);
      });
  }
  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };

  removeStudent() {
    // this.modalServices.show();

    this.dialog
    .open(RemoveStudentModalComponent, {
      data: {
        student: this.student,
      },
      minWidth: '322px'
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.parentServices.removeStudent(this.student.studentId).subscribe(() => {
          this.parentServices.getStudentsByParentNoCache().subscribe(() => {
            
          })
          this.router.navigateByUrl("/parent/students");
          this.toastr.success(
            `${this.firstName} ${this.lastName} successfully removed. However, it could take a few minutes before the student is fully removed from this account.`
          );
        }, error => {
          this.toastr.error(
            `Could not delete ${this.firstName} ${this.lastName}. Try again.`
          );
        });
      }
    });
  }

  cancelRemove() {
    this.modalServices.hide();
  }

  confirmRemove() {
    this.modalServices.hide();
    this.parentServices.removeStudent(this.student.studentId).subscribe(() => {
      this.router.navigateByUrl("/parent/students");
      this.toastr.warning(
        `Successfully deleted ${this.firstName} ${this.lastName}`
      );
    });
  }

  gotoSurvey() {
    this.router.navigate([
      "/teacher/classes",
      this.student.studentId,
      "survey",
    ]);
  }

  gotoCertificates() {
    this.router.navigate([
      "/teacher/classes",
      this.student.studentId,
      "certificate",
    ]);
  }

  gotoFiles() {
    this.router.navigate(["/teacher/classes", this.student.studentId, "files"]);
  }

  gotoExpectations() {
    this.router.navigate([
      "/teacher/classes",
      this.student.studentId,
      "expectations",
    ]);
  }
}
