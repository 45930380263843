import { SurveyPreview } from './../../../../../routes/teacher/survey-manager-route/create-survey/create-survey.component';
import { SurveyService } from './../../../../../shared/services/survey.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CertificateService } from 'src/app/shared/services/certificate.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-student-answer',
  templateUrl: './student-answer.component.html',
  styleUrls: ['./student-answer.component.scss']
})
export class StudentAnswerComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private toastr: ToastrService,
    private certificateServices: CertificateService,
    private scroller: ViewportScroller
  ) { }

  surveyTitle = '';
  headerUrl: any;
  surveyColor: any;
  completionDate: Date | string;
  selectedQuestions: SurveyPreview[] = [];
  questionNum = 0;
  settings: any;
  dueDate: any;
  percentComplete: any;

  index = 0;
  total: number;
  responses: any[] = [];
  percent: number = 0;
  percentArray: any[] = [];
  studentId = null;
  surveyId = null;
  answered = [];

  ngOnInit(): void {
    this.route.params.subscribe((p) => {
      this.studentId = this.route.parent.snapshot.params.studentId;
      this.surveyId = p.surveyId;
      this.certificateServices.getThumbnails().subscribe((settings)=>{
        this.settings = settings;
      })
      this.surveyService.getSurveyAnswers(this.surveyId, this.studentId).subscribe((answers) => {
        console.log('here- Answers', answers);
        if(answers.response != null){
          this.percentComplete = answers.response.percent;
        }
        if(answers.response == null){
          this.percentComplete = 0;
        }
        if (!answers.isAnswered) {
          this.surveyService.getSurveyById(this.surveyId).subscribe((survey) => {
            this.headerUrl = survey.headerUrl;
            this.surveyColor = survey.color;
            this.surveyTitle = survey.name;
            this.dueDate = survey.dueDate;
            this.selectedQuestions = survey.questions;
            this.percent = 0;
            console.log(survey, 'survey~~')
            this.initQuestion();
          });
        } else {
          this.headerUrl = answers.response.headerUrl;
          this.surveyColor = answers.response.surveyColor;
          this.surveyTitle = answers.response.surveyTitle;
          this.selectedQuestions = answers.response.selectedQuestions;
          this.percent = answers.response.percent;
          this.initQuestion();
        }

        console.log('here- selectedQue', this.selectedQuestions, this.questionNum);
        this.scrollToSurvey();
      });

    });
  }

  scrollToSurvey(){
    document.getElementById("student-answer").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  initQuestion() {
    this.questionNum = this.selectedQuestions.length;
    this.selectedQuestions.forEach(element => {
      if (element.answer && element.answer.length > 0) {
        this.percentArray.push(1);
      } else {
        this.percentArray.push(0);
      }
    });
  }

  getQuestions(survey: any) {
    var qstns = survey.questions;
    var finalQstns = [];
    survey.questions.forEach(element => {
      finalQstns.push({
        id: element,
        question: qstns[element],
        answer: ''
      });
      this.answered[element] = null;
    });
    return finalQstns;
  }

  nextStudent() {
    --this.index;
  }
  prevStudent() {
    ++this.index;
  }

  qChange(item, idx) {
    if (item.answer.length > 0) {
      this.percentArray[idx] = 1;
    } else {
      this.percentArray[idx] = 0;
    }
    const total = this.questionNum;
    let cal = 0;
    console.log('here-- percentArray', this.percentArray, total);
    this.percentArray.forEach(val => {
      cal = cal + val;
    });
    this.percent = Math.trunc((cal / total) * 100);
  }

  cancel() {
    this.router.navigate(['student/profile', this.studentId, 'surveys']);
  }
  saveProgress() {
    // this.surveyService.saveProgress().subscribe(()=>{
    //   this.router.navigate(['/student/profile',this.studentId,'surveys'])
    // })
  }

  send() {
    const surveyModel = {
      headerUrl: this.headerUrl,
      surveyColor: this.surveyColor,
      surveyTitle: this.surveyTitle,
      selectedQuestions: this.selectedQuestions,
      percent: this.percent
    };
    this.surveyService.saveAnswers(this.surveyId, this.studentId, surveyModel).subscribe((res) => {
      console.log(res, 'here-');
      this.toastr.success('Survey Submitted');
      this.router.navigate(['student/profile', this.studentId, 'surveys']);
    }, (err) => {
      this.toastr.error('Error');
      console.log('here- Error From Res', err );
    });
  }
}
