import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { StudentsService } from "src/app/shared/services/students.service";
import { IStudentResume } from "src/app/shared/components/student-list-widget/models/IStudentResume";
import { ISchoolInfo } from "src/app/shared/components/student-list-widget/models/ISchoolInfo";
import { ParentsService } from "../../services/parents.service";
import { ProfileService } from "../../services/profile.service";

@Component({
  selector: "app-student-profile",
  templateUrl: "./student-profile.component.html",
  styleUrls: ["./student-profile.component.scss"],
})
export class StudentProfileComponent implements OnInit {
  student: IStudentResume;
  schoolInfo: ISchoolInfo;
  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private parentServices: ParentsService,
    public ps: ProfileService
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((data) => {
        this.studentService.getStudentById(data.studentId).subscribe((student) => {
            this.student = student;
            this.studentService.setStudent(student);
          });
        this.studentService.getStudentSchoolInfo(data.studentId).subscribe((schoolInfo) => {
          this.schoolInfo = schoolInfo;
        });
    });
  }
}
