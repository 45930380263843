import { DashboardModel } from "./../../routes/student/dashboard-route/dashboard/models/DashboardModel";
import { ProfileService } from "./profile.service";
import { IRoleServices } from "./IRoleServices";
import { IEmployeeContact } from "./parent.services.models";
import { IStudentResume } from "./../components/student-list-widget/models/IStudentResume";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { ContactModel } from "../components/contact-widget/models/ContactWidgetModel";
import { AppConfigService } from "src/app/services/app-config.service";

@Injectable({
  providedIn: "root",
})
export class ParentsService implements IRoleServices {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices; //"https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService
  ) {
    let identity = this.oauth.getIdentityClaims() as any;
    this.parentId = identity?.usernameNo;
    this.parentUsername = identity?.sub;
  }
  getContacts(id: string): Observable<ContactModel[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/contacts`;
    return this.api
      .get(path)
      .pipe(map((response) => response as ContactModel[]));
  }
  getStudentsByParent(): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getStudentsByParentId(id): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${id}/students`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getStudentsByParentNoCacheAdmin(id): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${id}/students`;
    return this.api
      .getNoCache(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getStudentsByParentNoCache(): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students`;
    return this.api
      .getNoCache(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getSchoologyRemoteLogin(
    platform: string,
    schoologyId: string
  ): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/schoologyRemoteLogin?platform=${platform}&schoologyId=${schoologyId}`;
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
  getSchoologyRemoteLoginAssigment(
    platform: string,
    schoologyId: string,
    taskId: string
  ): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/schoologyRemoteLogin?platform=${platform}&schoologyId=${schoologyId}&source=assignment&id=${taskId}`;
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
  getSchoologyRemoteLoginCourse(
    platform: string,
    schoologyId: string,
    courseId: string
  ): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/schoologyRemoteLogin?platform=${platform}&schoologyId=${schoologyId}&source=course&id=${courseId}`;
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
  getContactsByParent(): Observable<ContactModel[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/contacts`;
    return this.api
      .get(path)
      .pipe(map((response) => response as ContactModel[]));
  }
  getDashboardByParent(): Observable<DashboardModel> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/dashboard`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getEsyLogin(): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/esyRemoteLogin`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  confirmStudent(registerModel: any) {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${registerModel.studentId}/confirm`;
    let form = {
      pin: registerModel.pinCode,
      birthDate: registerModel.birthDate,
    };
    return this.api
      .postForms(path, form)
      .pipe(map((response) => response as any));
  }
  addStudent(registerModel: any) {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${registerModel.studentId}`;
    let form = {
      pin: registerModel.pinCode,
      birthDate: registerModel.birthDate,
    };
    return this.api
      .postForms(path, form)
      .pipe(map((response) => response as any));
  }
  removeStudent(studentId: any, parentId: any = null) {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${studentId}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
  removeStudent2(studentId: any, parentId: any) {
    let path = `${this.baseUrl}/v1/parents/${parentId}/students/${studentId}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
  updateEmergencyContact(model: any): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/`;
    return of(model);
  }
  getParentIdByMisisId(misisId: any): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${misisId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}
