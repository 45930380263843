import { Subscription } from "rxjs";
import { BusService } from "./../../../../shared/services/bus.service";
import { IFaqGroup } from "./../../../../shared/components/faq-section-widget/models/Faq";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import { HelpCenterService } from "src/app/shared/services/help-center.service";
import { GeocodeService } from "../../../services/geocode.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import {
  Profile,
  ProfileService,
} from "src/app/shared/services/profile.service";
import swal from "sweetalert";
import { NotificationServices } from "src/app/shared/services/notification.service";

// import {} from '@types/googlemaps';
declare var google;

declare var ol: any;
@Component({
  selector: "app-student-bus",
  templateUrl: "./student-bus.component.html",
  styleUrls: ["./student-bus.component.scss"],
})
export class StudentBusComponent implements OnInit {
  zoom: number = 10;
  // initial center position for the map
  lat: number = 33.9391064;
  lng: number = 118.0535701;
  segment = "pickup";
  isMapEnabled: boolean = false;
  routeTracking: Subscription = null;
  stopLocation: any = null;
  tracking: any = null;
  time: any = null;
  isAfterHours: boolean = null;
  studentId = null;
  pickup = null;
  dropoff = null;
  intervalTracking = null;
  markers: any[] = [{}, {}, {}];
  bus: any = null;
  faq: IFaqGroup[] = null;
  lastUpdated: any;
  id;
  schoolFailed = false;
  pickupEnabled = false;
  dropoffEnabled = false;
  fitBounds: boolean = true;
  sub: Subscription;
  initial = 0;

  options = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ] as google.maps.MapTypeStyle[];

  googleMaps: any;
  @ViewChild("map") mapElementRef: ElementRef;
  busMarker;

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private helpService: HelpCenterService,
    private busService: BusService,
    private geocodeService: GeocodeService,
    public tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private renderer: Renderer2
  ) {}



  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getGoogleMaps()
      .then((googleMaps) => {
        this.googleMaps = googleMaps;
        this.createMarkers(this.markers);
        this.enableMap();
        this.helpService.getFaq("Transportation").subscribe((faq) => {
          this.faq = faq;
        });

      })
      .catch((err) => {
        console.log(err);
        console.log("retrying");
      });
    // setTimeout(() => {
    //   const check = document.getElementsByClassName("gm-style");
    //   if (check.length < 1) {
    //     this.getGoogleMaps()
    //       .then((googleMaps) => {
    //         this.googleMaps = googleMaps;
    //         this.createMarkers(this.markers);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         console.log("retrying");
    //       });
    //   }
    // }, 5000);

    //this.loadData(null);
  }
  loadData(event) {
    this.createMarkers(this.markers);
    this.enableMap();
    this.helpService.getFaq("Transportation").subscribe((faq) => {
      this.faq = faq;
    });
    if (event) {
      event.target.complete();
    }
  }

  createMarkers(markers) {
    this.id = this.studentService.currentStudent().studentId;
    this.studentService
      .getStudentSchoolInfo(this.studentService.currentStudent().studentId)
      .subscribe((schoolInfo) => {
        let schollName = schoolInfo.schoolName;
        return this.geocodeService
          .geocodeAddress(`${schoolInfo.schoolName}`)
          .subscribe((location) => {
            if (location) {
              this.schoolFailed = false;
              this.stopLocation = schollName;
              this.lat = location["lat"];
              this.lng = location["lng"];
              markers[0] = {
                lat: Number(location["lat"]),
                lng: Number(location["lng"]),
                icon: "/assets/img/school_map_marker.png",
                label: schollName,
              };
            } else {
              this.schoolFailed = true;
            }
          });
      });

    this.studentService
      .getStudentBusRoutes(this.studentService.currentStudent().studentId)
      .subscribe((response) => {
        this.bus = response;
        if (response.routes?.length > 0) {
          this.pickupEnabled = response.routes[0].stopLocation != null;
          this.dropoffEnabled = response.routes[1].stopLocation != null;
          this.pickup = response.routes[0];
          this.dropoff = response.routes[1];
          let address = this.pickup.stopLocation;
          if (address) {
            address = address.substring(0, address.lastIndexOf(" "));
            if (this.checkTime()) {
              this.studentService
                .getStudentTracking(
                  this.studentService.currentStudent().studentId,
                  response.routes[0].routeNumber
                )
                .subscribe((tracking) => {
                  if (tracking) {
                    this.studentId =
                      this.studentService.currentStudent().studentId;
                    this.tracking = tracking;
                    markers[2] = {
                      lat: Number(tracking[0]?.latitude),
                      lng: Number(tracking[0]?.longitude),
                      icon: "/assets/img/bus_map_marker.png",
                      label: "Bus Location",
                    };
                  }
                });
            }

            return this.geocodeService
              .geocodeAddress(response.routes[0].stopLocation)
              .subscribe((location) => {
                if (location) {
                  this.stopLocation = address;
                  this.lat = location["lat"];
                  this.lng = location["lng"];
                  markers[1] = {
                    lat: Number(location["lat"]),
                    lng: Number(location["lng"]),
                    icon: "/assets/img/stop_map_marker.png",
                    label: this.pickup.stopLocation,
                  };
                  this.jsMap(markers);
                }
              });
          }
        }
      });

    this.fitBounds = false;
  }

  jsMap(markers) {
    const mapEl = this.mapElementRef.nativeElement;
    const marker = markers.find(m=>m.lat)
    const map = new this.googleMaps.Map(mapEl, {
      center: { lat: marker.lat, lng: marker.lng },
      zoom: 10,
      mapTypeControlOptions: {
        mapTypeIds: ["mystyle"],
      },
      mapTypeId: "mystyle",
      mapTypeControl: false,
    });
    this.googleMaps.event.addListenerOnce(map, "idle", () => {
      this.renderer.addClass(mapEl, "visible");
    });

    map.mapTypes.set(
      "mystyle",
      new google.maps.StyledMapType(this.options, { name: "My Style" })
    );

    markers.forEach((mark) => {
      if (mark && mark !== {}) {
        const marker = new this.googleMaps.Marker({
          position: { lat: mark.lat, lng: mark.lng },
          // eslint-disable-next-line object-shorthand
          map: map,
          title: mark.label,
          icon: mark.icon,
          draggable: false,
        });
        if (mark.label === "Bus Location") {
          this.busMarker = marker;
        }
        marker.setMap(map);
      }
    });
  }

  enableMap() {
    if (this.isMapEnabled) return;
    this.isMapEnabled = true;
    let self = this;
    this.intervalTracking = setInterval(() => {
      if (this.checkTime()) {
        this.studentService
          .getStudentTracking(self.studentId, self.pickup.routeNumber)
          .subscribe((tracking) => {
            this.tracking = tracking;
            if (this.busMarker && this.tracking) {
              const latLng = new google.maps.LatLng(
                Number(tracking[0].latitude),
                Number(tracking[0].longitude)
              );
              this.busMarker.setPosition(latLng);
            } else {
              this.markers[2] = {
                lat: Number(tracking[0].latitude),
                lng: Number(tracking[0].longitude),
                icon: "/assets/img/bus_map_marker.png",
                label: "Bus Location",
              };
              this.jsMap(this.markers);
            }
          });
      }
    }, 3000);
  }

  phoneNumber(value: string): string {
    var cleaned = ("" + value).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  checkTime(): boolean {
    const hour = new Date().getHours();
    if (hour >= 6 && hour <= 9) {
      return true;
    } else if (hour >= 13 && hour <= 17) {
      return true;
    } else {
      return true;
    }
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: "above" };
    let below: MdMenuPlacement = { yPosition: "below" };
    let left: MdMenuPlacement = { xPosition: "before" };
    let right: MdMenuPlacement = { xPosition: "after" };

    this.tourService.initialize([
      {
        anchorId: "start-tour",
        content: "Bus routes will be updated daily here",
        title: "Welcome to your Bus Routes!",
        placement: above,
      },
    ]);
    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: "warning",
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, turn off tour",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
  }

  private getGoogleMaps(): Promise<any> {
    const win = window as any;
    const googleModule = win.google;
    if (googleModule && googleModule.maps) {
      return Promise.resolve(googleModule.maps);
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${localStorage.getItem(
        "app_gkey"
      )}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        const loadedGoogleModule = win.google;
        if (loadedGoogleModule && loadedGoogleModule.maps) {
          resolve(loadedGoogleModule.maps);
        } else {
          reject("Google maps SDK not available");
        }
      };
    });
  }
  ngOnDestroy() {
    if (this.intervalTracking) clearInterval(this.intervalTracking);
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
