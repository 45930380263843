import { Router } from '@angular/router';
import { NotificationServices } from './notification.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of,throwError  } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { LoaderService } from "./loader.service";
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

@Injectable({
  providedIn: "root",
})
export class LoaderInterceptorService implements HttpInterceptor {
  private count = 0;

  constructor(private loaderService: LoaderService, private notificationService:NotificationServices,private router:Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const spinner = req.params.get("spinner");
    if (req.url.indexOf("/picture") >= 0) return next.handle(req);
    if (req.url.indexOf("/profile") >= 0) return next.handle(req);
    if (req.url.indexOf("/routes") >= 0) return next.handle(req);
    if (req.url.indexOf("/comments") >= 0) return next.handle(req);
    if (req.url.indexOf("/tracking") >= 0) return next.handle(req);
    if (req.url.indexOf("/messages") >= 0) return next.handle(req);
    if (req.url.indexOf("/translator") >= 0) return next.handle(req);
    var conversationIdRegex = "/conversations/d*";
    if (req.url.match(conversationIdRegex)) {
      return next.handle(req);
    }
    // console.log(req.url)
    if (this.count === 0 && !spinner) {
      this.loaderService.setHttpProgressStatus(false);
    } else if (this.count === 0 && spinner === "no") {
      this.loaderService.setHttpProgressStatus(false);
    }
    this.count++;
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse)  => {
        if(err.error?.InnerException?.Message == "LAUSD Services is offline"){
          this.notificationService.showErrorToast('LAUSD Services are not available');
        } else {
          //console.error('Cannot complete the request')
          if(err.status == 403){
            this.router.navigate(["auth","404"]);
          }
          else if (err.status == 401){

          }else{
            //this.notificationService.showErrorToast('Cannot complete the request');
            console.log('Cannot complete the request');
          }
        }
        return throwError(err);
      }),
      finalize(() => {
        this.count--;
        if (this.count === 0) {
          this.loaderService.setHttpProgressStatus(false);
        }
      })
    );
  }
}
