import { CdnService } from './../../../services/cdn.service';
import { IFeed, IAction } from "./models/IFeed";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoaderService } from "../../services/loader.service";
import * as moment from "moment";
import { ProfileService } from "../../services/profile.service";
import { CalendarService } from "../../services/calendar.service";
import { NotificationServices } from "../../services/notification.service";
import { Router } from "@angular/router";
import swal from "sweetalert";
import { EventsAttendanceService } from "../../services/events-attendance.service";
import { ToastrService } from "ngx-toastr";
import { Observable,of } from "rxjs";
import { CertificateService } from "../../services/certificate.service";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
// const cheerio = require('cheerio');
@Component({
  selector: "app-feed-widget",
  templateUrl: "./feed-widget.component.html",
  styleUrls: ["./feed-widget.component.scss"],
})
export class FeedWidgetComponent implements OnInit {
  @Input() feed: IFeed = null;
  @Input() isPreview = false;
  @Output() onAction = new EventEmitter<IAction>();

  role = this.profileService.profile.role;
  postedBy$: Observable<string>
  isRsvp = false;
  settings: any = null;

  get isAfterEvent() {
    let now = moment();
    if (now.isAfter(moment(this.feed.extraData.startTime))) return true;
    else return false;
  }
  constructor(
    private loaderService: LoaderService,
    private profileService: ProfileService,
    private calendarServices: CalendarService,
    private notificationServices: NotificationServices,
    private router: Router,
    private eventService: EventsAttendanceService,
    private toastr: ToastrService,
    private certificateServices: CertificateService,
    public rs: RoleServiceFactoryService,
    public cndService: CdnService
  ) {}

  basePath = "";
  allDay: boolean = false;

  ngOnInit(): void {
    if (this.feed.type == "calendar") {
      this.feed.startDay = this.feed.extraData?.startTime;
      this.feed.endDay = this.feed.extraData?.endTime;
      this.feed.title = this.feed.name;
      this.feed.allDay = this.feed.extraData?.allDay;
      this.feed.startTimeString = this.feed.extraData.startTime?.toString();
      this.feed.endTimeString = this.feed.extraData.endTime?.toString();
      this.checkAllDay();
      this.certificateServices.getThumbnails().subscribe((settings) => {
        this.settings = settings;
        this.basePath = `https://${settings.s3PublicRepository}.s3.us-west-2.amazonaws.com/`
      });
      this.eventService
        .getAttendanceStatusById(
          this.feed.id,
          this.profileService.identity.userIdRole
        )
        .subscribe((response) => {
          if (response.rsvp) {
            this.isRsvp = true;
          }
        });
        var by  = this.feed.staffId || this.feed.extraData.staffId;
        if(this.feed.staffId){
          this.profileService.getProfileById(this.feed.staffId).subscribe((profile)=>{
            var fullname = `${profile.lastName}, ${profile.firstName}`
            this.postedBy$ = of(fullname);
          })
        }else{
            this.postedBy$ = of('N/A')
        }

    }
  }

  checkAllDay(){
    if(this.feed.startDay == this.feed.endDay){
      this.allDay = true;
    } else {
      this.allDay = false;
    }
  }



  hasImg(content: string) {
    if (content == null) return false;
    return content.indexOf("img") >= 0;
  }

  visit(feed: IFeed) {
    let action: IAction = {
      target: feed,
      command: "visit",
    };
    this.onAction.emit(action);
  }

  goToArticle(url: string) {
    window.open(url, "_blank");
  }

  calendarAddEvent(feed: any) {
    let userType = this.profileService.profile.role;

    let startDate = moment(feed.startDay).format("YYYY-MM-DD");
    let endDate = moment(feed.endDay).format("YYYY-MM-DD");
    this.loaderService.setHttpProgressStatus(true);
    this.calendarServices
      .createEvent(
        feed.title,
        startDate + " " + feed.startTimeString,
        endDate + " " + feed.endTimeString,
        this.profileService.identity.userIdRole
      )
      .subscribe(
        (res) => {
          this.loaderService.setHttpProgressStatus(false);
          this.notificationServices.showSuccessToast(
            `New Reminder/Event has been added successfully.`
          );
          // this.router.navigate([`/${userType.toLocaleLowerCase()}/calendar`]);
        },
        (error) => {
          this.notificationServices.showInfoToast(error);
        }
      );
  }

  loadImg(desc) {
    //  let cheerio$: any = cheerio.load(desc);
    //  let url = cheerio$('p').find('img:eq(0)').attr('src');
    //  if(url == undefined){
    //   url = './assets/img/preloader/preloader.full.png';
    //  }
    //  return url;
  }

  getText(desc) {
    let text = "";
    try {
      // let cheerio1$: any = cheerio.load(desc);
      // text = cheerio1$.text();
    } catch (error) {}
    return text;
  }

  rsvp(feed) {
    swal({
      title: `RSVP to event?`,
      icon: "info",
      text: "Your name will be added to the list of attendees and be visible to everyone",
      buttons: {
        cancel: true,
        confirm: {
          text: "Confirm",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        const userId = this.profileService.identity.userIdRole;
        const eventId = feed.id;
        let req = {
          attendeeId: userId,
          channel: "Web",
          role: this.role,
        };
        this.eventService.checkinEvent(eventId, req).subscribe(
          (res) => {
            console.log(res);
            this.toastr.success(
              `Successfully RSVP'd to the ${feed.title} event and added to your calendar!`
            );
            if (res.rsvp) {
              this.isRsvp = true;
            }
          },
          (error) => {
            this.toastr.warning("Could not RSVP. Try again.");
          }
        );
      }
    });
  }

  viewEventDetails(feed) {
    let userType = this.profileService.profile.role;
    if (
      userType == "Administrator" ||
      userType == "Teacher" ||
      userType == "Counselor"
    ) {
      // this.router.navigate([userType.toLocaleLowerCase(), 'events', feed.id])
      this.router.navigate([
        userType.toLocaleLowerCase(),
        "events",
        "id",
        feed.id,
      ]);
    } else {
      this.router.navigate([
        userType.toLocaleLowerCase(),
        "events",
        "id",
        feed.id,
      ]);
    }
  }
}
