import { AccomplishmentService } from './../../services/accomplishment.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAccomplishmentModalComponent } from '../add-accomplishment-modal/add-accomplishment-modal.component';
import { ActivatedRoute } from '@angular/router';
import { SchoolYearService } from '../../services/school-year.service';
import { CourseList, IStudentGrades, TermList } from '../../services/student.service.models';
import { StudentsService } from '../../services/students.service';
import { CommentsModalComponent } from '../comments-modal/comments-modal.component';
import * as _ from 'lodash';
import { RemindGradeComponent } from '../remind-grade/remind-grade.component';
import { ContactDetailsWidgetComponent } from '../contact-details-widget/contact-details-widget.component';
import { ProfileService } from '../../services/profile.service';
import * as _m from 'moment'
import { ToastrService } from 'ngx-toastr';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { NotificationServices } from '../../services/notification.service';
import swal from 'sweetalert'


@Component({
  selector: 'app-student-ccrg',
  templateUrl: './student-ccrg.component.html',
  styleUrls: ['./student-ccrg.component.scss']
})
export class StudentCcrgComponent implements OnInit {

  m = _m;
  tabActive = 1;
  accTabActive =1;

  faq1 = false;
  faq2 = false;
  faq3 = false;
  faq4 = false;
  faq5 = false;

  grades: IStudentGrades = null;
  gradesLY: IStudentGrades = null;
  term: TermList = null;
  termLY: TermList = null;
  courses: CourseList[] = [];
  coursesLY: CourseList[] = [];
  courseAlerts: any[];
  courseAlertsLY: any[];

  studentId: any;
  gpaList: any;
  gpaModel: any = null;

  role: any;

  gotReport: boolean = false;
  isDownloading: boolean = false;
  successEng: boolean = false;
  successSp: boolean = false;
  failEng: boolean = false;
  failSp: boolean = false;
  errorReport: boolean = false;
  englishReport: any = null;
  spanishReport: any = null;

  message: any = '';
  accomplishments: any = []
  typeName = null;
  noteId = null;

  isLoading = false;

  get isParent() {
    if (this.profileService.profile.role == 'Parent') return true
    else return false
  }

  constructor(
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private schoolYear: SchoolYearService,
    private profileService: ProfileService,
    private accomplishmentService:AccomplishmentService,
    private activatedRoute:ActivatedRoute,
    private toastr: ToastrService,
    private readonly tourService: TourService,
    private notificationServices: NotificationServices

  ) { }

  ngOnInit(): void {
    this.typeName = this.activatedRoute.snapshot.params.typeName || null;
    this.noteId = this.activatedRoute.snapshot.params.noteId || null;
    this.studentId = this.activatedRoute.parent.snapshot.params.studentId;
    this.getStudentGrades();
    this.getStudentGpa();
    this.role = this.profileService.identity.userType;
    this.accomplishmentService.list('Awards', this.studentId).subscribe((response)=>{
      this.accomplishments = this.processAccomplishments(response);
      if(this.typeName){
        this.changeTab(this.typeName)
      }
    })

    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'ccrg1',
      content: 'Welcome to your College and Career Readiness. Here you will have access to all things related to your future in college.',
      title: 'College and Career Readiness',
      placement: below,
    },
    {
      anchorId: 'ccrg2',
      content: 'You can download the guide.',
      title: 'College and Career Readiness Guide',
      placement: below,
    },
    {
      anchorId: 'ccrg3',
      content: 'You can view and keep track of accomplishments made by the student here.',
      title: 'Accomplishments',
      placement: below,
    },
    {
      anchorId: 'ccrg4',
      content: 'Any courses with alerts/warnings will be viewable here.',
      title: 'Course Alerts',
      placement: below,
    },
    {
      anchorId: 'ccrg5',
      content: 'Here you will find that the LAUSD App will calculate all relevant GPAs for you.',
      title: 'GPA',
      placement: below,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }
  
  changeTab(type){

    var menuId = 0;
    if(type == 'Awards') menuId = 1;
    if(type == 'EdPrograms') menuId = 2;
    if(type == 'ExtraCurricular') menuId = 3;
    if(type == 'CollegeClasses') menuId = 4;
    if(type == 'WorkExp') menuId = 5;
    if(type == 'Volunteer') menuId = 6;
    this.tabActive = 2;
    this.switchAccTab(menuId)
  }
  getStudentGpa() {
    this.studentService.getStudentGpa(this.studentId, this.schoolYear.currentSingleYear()).subscribe((gpa) => {
      this.gpaList = gpa;
      this.gpaModel = this.getGpaModel(this.gpaList);
      console.log(this.gpaList);
      console.log(this.gpaModel);

    })
  }
  getGpaModel(gpaList) {
    let gpaModel = {
      weighted: '',
      unweighted: '',
      uc: '',
      csu: '',
      financialAid: '',

    }
    _.each(gpaList, gpa => {
      switch (gpa.gpaTypeDescr) {
        case 'LAUSD (W) GPA':
          gpaModel.weighted = gpa.gpaValue;
          break;
        case 'LAUSD (UNW) GPA':
          gpaModel.unweighted = gpa.gpaValue;
          break;
        case 'UC (W) GPA':
          gpaModel.uc = gpa.gpaValue;
          break;
        case 'CSU GPA':
          gpaModel.csu = gpa.gpaValue;
          break;
        case 'Financial Aid (Post) GPA':
          gpaModel.financialAid = gpa.gpaValue;
          break;

        default:
          break;
      }
    })
    return gpaModel
  }

  getStudentGrades() {
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentService.getStudentGradesByYear(data.studentId,this.schoolYear.currentYear()).subscribe((response) => {
        this.grades = response;
        if (this.grades?.termList?.length > 0) {
          this.term = this.grades.termList[0];
          this.filterCourses(0);
          this.courseAlerts = this.checkGradeAlert(this.term);
        } else {
          this.courseAlerts = [];
        }
      });
      this.studentService.getStudentHistoricalGrades(data.studentId,this.schoolYear.lastYear()).subscribe((response) => {
        this.gradesLY = response;
        if (this.gradesLY.termList.length > 0) {
          this.termLY = this.gradesLY.termList[0];
          this.filterCoursesLY(0);
          this.courseAlertsLY = this.checkGradeAlert(this.term);
        } else {
          this.courseAlertsLY = [];
        }
      });
    });
  }
  filterCourses(index: any) {

    this.term = this.grades.termList[index];
    let list = [];
    for (const assessment in this.term.assessmentList) {
      const courseList = this.term.assessmentList[assessment].courseList;
      list = list.concat(courseList);
    }

    this.courses = list;
  }
  filterCoursesLY(index: any) {

    this.termLY = this.gradesLY.termList[index];
    let list = [];
    for (const assessment in this.termLY.assessmentList) {
      const courseList = this.termLY.assessmentList[assessment].courseList;
      list = list.concat(courseList);
    }

    this.coursesLY = list;
  }
  checkGradeAlert(term){
    let failList = [];
    _.each(term?.assessmentList[0]?.courseList, course => {
      _.each(course.gradeList, grade => {
        if (grade.grade == "F" || grade.grade == "D"){
          grade.courseId = course.courseId;
          grade.courseName = course.courseName;
          grade.gradeLevel = term.gradeLevel;
          grade.yearReceived = term.termEndDate;
          grade.teacher = {
            instructorEmail: grade.instructorEmail,
            instructorEmployeeId: grade.instructorEmployeeId,
            instructorName: grade.instructorName,
            role: 'Teacher'
          }
          failList.push(grade);
        }
      })
    })
    return failList
  }

  switchTab(index) {
    this.tabActive = index;
    this.faq1 = false;
    this.faq2 = false;
    this.faq3 = false;
    this.faq4 = false;
    this.faq5 = false;
  }
  switchAccTab(index) {
    this.accTabActive = index;
    let type = ' ';
    switch (index) {
      case 1:
        type = 'Awards';
        break;
      case 2:
        type = 'EdPrograms';
        break;
      case 3:
        type = 'ExtraCurricular';
        break;
      case 4:
        type = 'CollegeClasses';
        break;
      case 5:
          type = 'WorkExp';
          break;
      case 6:
        type = 'Volunteer';
        break;
      default:
        break;
    }
    this.isLoading = true;
    this.accomplishmentService.list(type, this.studentId).subscribe((result)=>{
      this.isLoading = false;
      this.accomplishments = this.processAccomplishments(result);
      let item = this.accomplishments.find(c=>c.id == this.noteId);
      if(this.noteId) {
        this.openComment(item);
      }
    }, error => {
      this.isLoading = false;
      this.toastr.error(`Can't load Accomplishments`);
    });

  }

  processAccomplishments(accomplishments) {
    const sorted: any[] = accomplishments.sort((a,b) => {
      return _m(b.created).diff(a.created)
    })
    return sorted
  }

  toggleFaq(index) {
    switch (index) {
      case 1:
        this.faq1 = !this.faq1;
        break;
      case 2:
        this.faq2 = !this.faq2;
        break;
      case 3:
        this.faq3 = !this.faq3;
        break;
      case 4:
        this.faq4 = !this.faq4;
        break;
      case 5:
        this.faq5 = !this.faq5;
        break;
      default:
        break;
    }
  }

  getReport() {
       this.isDownloading = true;
    this.message = 'Please wait while we download your report';
    this.studentService.getStudentPdfReport(this.studentId, 'ccrg', 'english').subscribe((report) => {
      this.englishReport = report.buffer;
      this.gotReport = true;
      this.successEng = true;
      this.message = 'Download was successful. Click to view.'
      if(this.englishReport.length < 1000) {
        this.successEng = false;
        this.failEng = true;
        this.message = 'Please check your internet connection and retry'
      }
    }, error => {this.failEng = true})
    this.studentService.getStudentPdfReport(this.studentId, 'ccrg', 'spanish').subscribe((report)=> {
      this.spanishReport = report.buffer;
      this.gotReport = true;
      this.successSp = true;
      this.message = 'Download was successful. Click to view.'
      if(this.spanishReport.length < 1000) {
        this.successSp = false;
        this.failSp = true;
        this.message = 'Please check your internet connection and retry'
      }
    }, error => {this.failEng = true})
  }

  downloadReport(lang) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    if(lang == 'english'){
      var blob = this.blobPdfFromBase64String(this.englishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `report_ccrg_english.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }
    if(lang == 'spanish'){
      var blob = this.blobPdfFromBase64String(this.spanishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `report_ccrg_spanish.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }


  }


  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };

  openComment(item) {
    this.dialog
    .open(CommentsModalComponent, {
      data: {
        item: item,
        studentId: this.studentId,
        ccrg: true
      },
      minWidth: '322px'
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {

      }
    });
  }

  openCounselor(contact) {
    console.log('open counselor', contact)
  }
  openTeacher(contact) {
    this.dialog
    .open(ContactDetailsWidgetComponent, {
      disableClose: true,
      data: {
        name: contact.instructorName,
        role: contact.role,
        email: contact.instructorEmail,
        id: contact.instructorEmployeeId
      }
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
      }
    });
  }

  like(item) {
    console.log('like', item)
  }


  edit(item, type) {
    this.dialog
      .open(AddAccomplishmentModalComponent, {
        disableClose: true,
        data: {
          editMode: true,
          type: type,
          item: item
        }
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.valid) {
          this.accomplishmentService.list(res.category, this.studentId).subscribe((response)=>{
            this.accomplishments = this.processAccomplishments(response);
          });
        }
      });
  }

  view(item, type) {
    this.dialog
    .open(AddAccomplishmentModalComponent, {
      disableClose: true,
      data: {
        viewMode: true,
        type: type,
        item: item
      }
    })
  }

  delete(item) {
    this.accomplishmentService.remove(item.id).subscribe((response)=>{
      this.accomplishmentService.list(item.accomplishmentCategory, this.studentId).subscribe((response)=>{
        this.accomplishments = this.processAccomplishments(response);
      });
    });
  }

  create(type) {
    this.dialog
      .open(AddAccomplishmentModalComponent, {
        disableClose: true,
        data: {
          type: type,
          editMode: false
        }
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.valid) {
          this.accomplishmentService.list(res.category, this.studentId).subscribe((response)=>{
            this.accomplishments = this.processAccomplishments(response);
          });
        }
      });
  }

  remind(course) {
    let student = this.studentService.currentStudent();
    this.dialog
      .open(RemindGradeComponent, {
        disableClose: true,
        data: {
          course: course,
          student: student
        }
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
        }
      });
  }

}
