import { Injectable } from '@angular/core';
import { Observable, Subject, of } from "rxjs";
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from "./api.service";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.operationServices;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService
  ) { }


  getLastUpdate() {
    const path = `${this.baseUrl}/v1/data-sync/lastUpdate`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}
