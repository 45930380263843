import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';
import { StudentsService } from './../../../../shared/services/students.service';
import { StudentPbisService } from './../../../../shared/services/student-pbis.service';
import { CertificateService } from 'src/app/shared/services/certificate.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ThumbnailPreviewComponent } from '../../thumbnail-preview/thumbnail-preview.component';
import * as m from 'moment';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';
import swal from 'sweetalert'
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-student-certificates',
  templateUrl: './student-certificates.component.html',
  styleUrls: ['./student-certificates.component.scss']
})
export class StudentCertificatesComponent implements OnInit {

  single = false;
  mockData = [

  ];

  tableData: any[] = [];
  studentProfile: any = null;
  now = new Date();
  settings = null;
  studentId = null;
  lastUpdated: any;

  isLoading = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private certificateServices: CertificateService,
    private studentServices: StudentsService,
    private studentPbis:StudentPbisService,
    public rs: RoleServiceFactoryService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((p)=>{
      this.isLoading = true;
      this.studentId = p.studentId;
      this.certificateServices.getThumbnails().subscribe((settings)=>{
        this.settings = settings;
      })
      this.studentPbis.getPbisCertificates(p.studentId).subscribe((data)=>{
        this.isLoading = false;
        this.tableData = data.certificates;
        //this.single = true;
      }, error => {
        this.isLoading = false;
        this.toastr.error(`Can't load Certificates`);
      });
      this.studentServices.getStudentById(p.studentId).subscribe((student)=>{
        this.studentProfile = student;
      })
      this.lastUpdated = m();

      this.startTour();
    })
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'When teachers award your student with a certificate, they will be accessible here',
      title: 'Certificates',
      placement: above,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  generateCert() {
    this.router.navigate(['generate'], { relativeTo: this.route });
  }

  previewThumbnail(itemId) {
    let pdfResource = 'https://'+this.settings.s3PublicRepository+'.s3.us-west-2.amazonaws.com/certificates/pdfs/certificate-'+itemId+'/'+this.studentId+'_'+itemId+'.pdf'
    let pngResource = 'https://'+this.settings.s3PublicRepository+'.s3.us-west-2.amazonaws.com/certificates/thumbnails/certificate-'+itemId+'/'+this.studentId+'_'+itemId+'.png'
    this.dialog.open(ThumbnailPreviewComponent, {
      disableClose: false,
      width: '800px',
      panelClass: 'thumbnail-preview',
      data: {
        thumbnail: pngResource,
        pdf: pdfResource,
        tablePreview: true
      }
    }).afterClosed().pipe(take(1)).subscribe(result => {
    });
  }

}
