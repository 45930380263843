export const Endpoints = {
  baseUri: "https://lausdappdev.lausd.net", //udpates
  authenticationServices : "https://servicesdev.lausd.net/authentication",
  notificationServices : "https://servicesdev.lausd.net/notifications",
  customerServices : "https://servicesdev.lausd.net/customers",
  communicationsServices : "https://servicesdev.lausd.net/communications",
  calendarServices : "https://servicesdev.lausd.net/calendar",
  facilitiesServices : "https://servicesdev.lausd.net/facilities",
}
export const environment = {
  production: false,
  chat: 'https://lausddev.service-now.com/$sn-va-web-client-app.do?sysparm_nostack=true&sysparm_stack=no&sysparm_portal=lausd&sysparm_page=customer_home&sysparm_language=en&sysparm_liveagent_application=csm&sysparm_live_agent_only=false&sysparm_branding_key=lausd_customer_service',
  portal: 'https://lausddev.service-now.com/lausd',
  environment_name: "Development",
  gaTrackingID: "UA-221977737-1",
  endPoints: Endpoints,
  oauth: {
    resourceServer: {
      allowedUrls: [
        Endpoints.authenticationServices,
        Endpoints.customerServices,
        Endpoints.calendarServices,
        Endpoints.notificationServices,
        Endpoints.facilitiesServices,
        Endpoints.communicationsServices
      ],
      sendAccessToken: true,
    }
  },
};
