import { IStudentGateProgram } from './../../../../shared/services/student.service.models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IFaqGroup } from 'src/app/shared/components/faq-section-widget/models/Faq';
import { HelpCenterService } from 'src/app/shared/services/help-center.service';
import { StudentsService } from 'src/app/shared/services/students.service';
import * as moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import swal from 'sweetalert'
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ProfileService } from 'src/app/shared/services/profile.service';


@Component({
  selector: 'app-student-gate',
  templateUrl: './student-gate.component.html',
  styleUrls: ['./student-gate.component.scss']
})
export class StudentGateComponent implements OnInit {

  lastUpdated: any;
  gateProgram: IStudentGateProgram = null
  faq: IFaqGroup[] = null;

  studentName: any;
  studentId = null;

  gotReport: boolean = false;
  isDownloading: boolean = false;
  successEng: boolean = false;
  successSp: boolean = false;
  failEng: boolean = false;
  failSp: boolean = false;
  englishReport: any = null;
  spanishReport: any = null;

  message: any = '';

  isLoading = false;

  selectedReport: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private helpService: HelpCenterService,
    private toastr: ToastrService,
    private readonly tourService: TourService,
    private notificationServices: NotificationServices,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.lastUpdated = moment();
    this.isLoading = true;
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.studentService
        .getStudentGate(this.studentId)
        .subscribe((response) => {
          this.isLoading = false;
          this.gateProgram = response;
          console.log(response)
        }, error => {
          this.isLoading = false;
          this.toastr.error(`Can't load GATE`);
        });

      let student = this.studentService.currentStudent();
      this.studentName = `${student.firstName} ${student.lastName}`
    });
    this.helpService.getFaq("GATEProgram").subscribe((faq) => {
      this.faq = faq;
    });

    this.startTour();
  }

  getReport(index) {
    this.selectedReport = index;
    this.isDownloading = true;
    this.message = 'Please wait while we download your report';
    this.studentService.getStudentPdfReport(this.studentId, 'gate', 'english').subscribe((report) => {
      this.englishReport = report.buffer;
      this.gotReport = true;
      this.successEng = true;
      this.message = 'Download was successful. Click to view.'
      if(this.englishReport.length < 1000) {
        this.successEng = false;
        this.failEng = true;
        this.message = 'Please check your internet connection and retry'
      }
    }
    // , error => {this.failEng = true}
    )
  }

  downloadReport(lang) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    if(lang == 'english'){
      var blob = this.blobPdfFromBase64String(this.englishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `gate_report_english.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }
    if(lang == 'spanish'){
      var blob = this.blobPdfFromBase64String(this.spanishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `gate_report_spanish.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }


  }

  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'gate1',
      content: 'Welcome to GATE. You can access GATE information and letters here.',
      title: 'GATE Program',
      placement: below,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }


}
