import { IStudentImmunization } from './../../../../shared/services/student.service.models';
import { IFaqGroup } from './../../../../shared/components/faq-section-widget/models/Faq';
import { HelpCenterService } from './../../../../shared/services/help-center.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import * as _ from 'lodash'
import { groupBy } from 'lodash';
import * as m from 'moment';

import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { Profile, ProfileService } from 'src/app/shared/services/profile.service';
import swal from 'sweetalert';
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: "app-student-immunization",
  templateUrl: "./student-immunization.component.html",
  styleUrls: ["./student-immunization.component.scss"],
})
export class StudentImmunizationComponent implements OnInit {

  doses: string[] = [];
  codes: string[] = null;
  immunizations: IStudentImmunization[] = [];
  lastImmunization: IStudentImmunization = null;
  groups: any = []
  faq: IFaqGroup[] = null;
  noData: boolean = false;
  legendData: boolean = true;
  lastUpdated: any;
  isLoading = false;

  exemptionLegend = [
    {
      code: 'PT',
      description: 'Personal - Temporary'
    },
    {
      code: 'V',
      description: 'Proof of Immunity'
    },
    {
      code: 'MT',
      description: 'Medical - Temporary'
    },
    {
      code: 'MR',
      description: 'Medical - Permanent'
    },
    {
      code: 'R',
      description: 'Religious'
    },
  ]



  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private helpService: HelpCenterService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService

  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentService
        .getStudentImmunization(data.studentId)
        .subscribe((response) => {
          this.isLoading = false;
          if(response.length == 0)
            return this.noData = response.length == 0
          this.immunizations = _.sortBy(response,['immunizationCode','immunizationDate'])
          this.groups =  _.groupBy(this.immunizations,'immunizationCode');
          let size = 0;
          _.forEach(this.groups,(item)=>{
            if(item.length > size){
              size = item.length
            }
          })
          this.doses = new Array(size);
          this.codes = Object.getOwnPropertyNames(this.groups);
          console.log(this.groups);
          this.lastImmunization = _.maxBy(this.immunizations,(item)=>item.immunizationDate);
          this.lastUpdated = m();
        }, error => {
          this.isLoading = false;
          this.toastr.error(`Can't load Immunization`);
        });
    });


    this.helpService.getFaq('Immunization').subscribe((faq)=>{
      this.faq = faq;
    })

    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([{
      anchorId: 'start-tour',
      content: 'Click on a row to view more details about your records',
      title: 'Welcome to your Immunizations!',
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }


}
