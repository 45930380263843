import { Component, OnInit } from '@angular/core';
import { StudentOnlineLibraryModel } from '../student-online-library/models/Student-OnlineLibrary'
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { TourModalComponent } from '../../tour-modal/tour-modal.component';
import * as _ from 'lodash';
import * as m from 'moment';
import swal from 'sweetalert';


@Component({
  selector: 'app-student-online-library',
  templateUrl: './student-online-library.component.html',
  styleUrls: ['./student-online-library.component.scss']
})
export class StudentOnlineLibraryComponent implements OnInit {

  libraryResources: StudentOnlineLibraryModel[];

  constructor(
    public tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices
  ) { }

  ngOnInit(): void {

    this.setResources();
    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([{
      anchorId: 'start-tour',
      content: 'The Los Angeles Unified School District provides free digital books available to all students.',
      title: 'Welcome to Online Library!',
    }
    ]);
    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  setResources() {

    this.libraryResources = [
      {
        title: "Los Angeles Public Library",
        description: "Digital books and reading content, tutoring, homework assistance, college and career information, and E-media such as movies and music.",
        howToAccess: "From the student's Schoology account, click the waffle icon and select <b>Los Angeles Public Library</b>. To login, students will need their Student Success Card or SSC and a 4-digit PIN which may be obtained from their teacher.",
        imagePath: "/assets/img/icons/onlineLibrary/LA_Library.png",
        imageCssClass:"max-width46"
      },
      {
        title: "Tech2go",
        description: "The Los Angeles Public Library is starting a new computer bundle program. Tech2go Computer Bundles come equipped with a Chromebook device and an internet hotspot for long-term use. Computer bundles are available to adults with a library card in good standing. All patrons must sign a Mobile Device User Agreement upon checkout at a library location.",
        howToAccess: "To learn about the program visit: <a href=\"https://lapl.org/tech2go/computer-bundles\" target=\"_blank\">https://lapl.org/tech2go/computer-bundles</a>.",
        imagePath: "/assets/img/icons/onlineLibrary/tech2go_logo.png",
        imageCssClass:"max-width90"
      },
      {
        title: "Sora by Overdrive",
        description: "Access to hundreds of digital books and audiobooks.",
        howToAccess: "From the student's Schoology account, click the waffle icon and select <b>Sora by Overdrive</b>. The first time logging in, click \"Find my school\" and search for \"Los Angeles Unified\".",
        imagePath: "/assets/img/icons/onlineLibrary/sora.png",
        imageCssClass:"max-width46"
      },
      {
        title: "MyOn by Renaissance",
        description: "Wide variety of digital books and audiobooks for transitional kindergarten through 12th grade. Availability of resources will vary depending on the student's grade level.",
        howToAccess: "From the student's Schoology account, click the waffle icon and select <b>Renaissance Learning</b>.",
        imagePath: "/assets/img/icons/onlineLibrary/Renaissance.jpg",
        imageCssClass:"max-width46"
      },
      {
        title: "LAUSD Digital Library",
        description: "A database of research-based articles and digital books such as ABC-Clio (academic reference works and periodicals primarily on topics such as history and social sciences), Encyclopedia Britannica (information on all or many branches of knowledge), Gale Databases (open access to scholarly, peer-reviewed content) and more.",
        howToAccess: "From the student's Schoology account, click the waffle icon and select <b>LAUSD Digital Library</b>. Students must enter their LAUSD MyMail username and password.",
        imagePath: "/assets/img/icons/onlineLibrary/lausd-logo.png",
        imageCssClass:"max-width46"
      },
      {
        title: "Destiny Library Manager",
        description: "Students can access digital books and other digital media owned by their school's library.",
        howToAccess: "From the student's Schoology account, click the waffle icon and select <b>Destiny Library</b>. From the landing page, click the gear icon and select your school. Then, click \"Destiny Discover\" on the left.",
        imagePath: "/assets/img/icons/onlineLibrary/destiny.png",
        imageCssClass:"max-width90"
      }
    ]

  }

}
