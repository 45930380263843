import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NAPipe } from './na.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [NAPipe, FilterPipe],
  exports: [NAPipe, FilterPipe],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
