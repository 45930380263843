import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CertificateService } from "../../services/certificate.service";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import * as m from "moment";
import { StudentsService } from "../../services/students.service";
import { ISchoolInfo } from "../student-list-widget/models/ISchoolInfo";
import { IStudentResume } from "../student-list-widget/models/IStudentResume";
import { StaffFilesService } from "../../services/staff-files.service";
import { ChecklistService } from "../../services/checklist.service";
import { ExpectationsService } from "../../services/expectations.service";

@Component({
  selector: "app-comment-notification-widget",
  templateUrl: "./comment-notification-widget.component.html",
  styleUrls: ["./comment-notification-widget.component.scss"],
})
export class CommentNotificationWidgetComponent implements OnInit {
  selectedData;
  certificate;
  checklist;
  file;
  expectation;
  settings = null;
  studentId;
  _m = m;
  student: IStudentResume;
  schoolInfo: ISchoolInfo;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private certificateService: CertificateService,
    public rs: RoleServiceFactoryService,
    private cdRef: ChangeDetectorRef,
    public studentService: StudentsService,
    public dialog: MatDialog,
    private staffFileService: StaffFilesService,
    private checklistService: ChecklistService,
    private expectationService: ExpectationsService
  ) {
    this.selectedData = this.data;
  }

  ngOnInit() {
    if (this.selectedData.category === "certificate") {
      this.certificateService.getThumbnails().subscribe((settings) => {
        this.settings = settings;
      });
      this.certificateService
        .getCertificate(this.selectedData.id)
        .subscribe((result) => {
          this.certificate = result;
          this.certificate.type = "certificate";
          this.certificate.data = result;
          this.certificate.created = Date.parse(result.created);
          this.certificate.sourceOwner = result.staffId;
          this.certificate.studentId = this.selectedData.sId;
        });
    } else if (this.selectedData.category === "file") {
      this.staffFileService
        .getFile(null, this.selectedData.id)
        .subscribe((result) => {
          this.file = result;
          this.file.type = "file";
          this.file.data = result;
          this.file.created = Date.parse(result.created);
          this.file.sourceOwner = result.staffId;
          this.file.studentId = this.selectedData.sId;
          this.loadFile(result);
        });
    } else if (this.selectedData.category === "checklist") {
      this.checklistService
        .getChecklistById(this.selectedData.id)
        .subscribe((result) => {
          this.checklist = result;
          this.checklist.type = "checklist";
          this.checklist.data = result;
          this.checklist.created = Date.parse(result.created);
          this.checklist.sourceOwner = result.staffId;
          this.checklist.studentId = this.selectedData.sId;

          console.log(this.checklist);
        });
    } else if (this.selectedData.category === "expectation") {
      this.expectationService
        .getExpectationById(this.selectedData.sId, this.selectedData.id)
        .subscribe((result) => {
          console.log(result.points[0].data);
          console.log(result.points[0].expectation);
          this.expectation = result.points[0];
          this.expectation.type = "expectation";
          this.expectation.data = result.points[0];

          this.expectation.sourceOwner = result.points[0].staffId;
          this.expectation.studentId = this.selectedData.sId;
          this.loadExpectation(this.expectation);
        });
    }

    this.loadStudent(this.selectedData.sId);
    this.cdRef.detectChanges();
  }

  loadExpectation(ev) {
    this.expectationService
      .getStaffExpectation(ev.data.staffId, ev.data.expectationId)
      .toPromise()
      .then((expectation) => {
        ev.expectation = expectation;
        ev.created = expectation.created;
      });
  }

  loadFile(event) {
    console.log(event);
    this.staffFileService
      .getDownloadLink(event.data.staffId, event.data.fileId)
      .subscribe((url) => {
        if (url) {
          const re = /(?:\.([^.]+))?$/;
          event.data.fileType = re.exec(url.filename)[1];
          let imgType: boolean = false;
          switch (event.data.fileType) {
            case "png":
              event.data.img = url.previewLink;
              imgType = true;
            case "jpg":
              event.data.img = url.previewLink;
              imgType = true;
            case "jpeg":
              event.data.img = url.previewLink;
              imgType = true;
            case "gif":
              event.data.img = url.previewLink;
              imgType = true;
            case "webm":
              event.data.img = url.previewLink;
              imgType = true;
            case "svg":
              event.data.img = url.previewLink;
              imgType = true;
            case "tiff":
              event.data.img = url.previewLink;
              imgType = true;
            case "tif":
              event.data.img = url.previewLink;
              imgType = true;
          }

          if (imgType == false) {
            event.data.img = null;
          }
        }
      });
  }

  loadStudent(id) {
    this.studentService.getStudentById(id).subscribe((student) => {
      this.student = student;
      this.studentService.setStudent(student);
    });
    this.cdRef.detectChanges();
  }

  download(item) {
    this.staffFileService
      .getDownloadLink(item.studentId, item.id)
      .subscribe((url) => {
        if (url) {
          window.open(url.downloadLink);
        }
      });
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }
}
