import { RoleServiceFactoryService } from "src/app/shared/services/role-service-factory.service";
import {
  ChatService,
  UserNotification,
} from "src/app/shared/services/chat.service";
import { ProfileService } from "./../../shared/services/profile.service";
import { NotificationServices } from "../../shared/services/notification.service";
import { OAuthService } from "angular-oauth2-oidc";
import {
  Component,
  OnInit,
  ViewChild,
  Injector,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Router } from "@angular/router";
const screenfull = require("screenfull");

import { UserblockService } from "../sidebar/userblock/userblock.service";
import { SettingsService } from "../../core/settings/settings.service";
import { MenuService } from "../../core/menu/menu.service";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TimelineCommentDialogComponent } from "src/app/shared/components/timeline-comment-dialog/timeline-comment-dialog.component";
import * as _ from "lodash";
import * as m from "moment";
import { CommentNotificationWidgetComponent } from "../../shared/components/comment-notification-widget/comment-notification-widget.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;
  role: string = "";
  logoSrc: string = "";
  notifications: UserNotification[];
  todayMessagesNames: string[] = [];
  weekMessagesNames: string[] = [];
  monthMessagesNames: string[] = [];

  todayCommentsNames: string[] = [];
  weekCommentsNames: string[] = [];
  monthCommentsNames: string[] = [];
  showTodayMessages = false;
  showThisWeekMessages = false;
  showThisMonthMessages = false;
  showTodayEvents = false;
  showThisWeekEvents = false;
  showThisMonthEvents = false;
  showTodayAcademics = false;
  showThisWeekAcademics = false;
  showThisMonthAcademics = false;
  showTodayCcrg = false;
  showThisWeekCcrg = false;
  showThisMonthCcrg = false;

  showTodayComments = false;
  showThisWeekComments = false;
  showThisMonthComments = false;

  get isProd() {
    if(environment.environment_name == 'Production') return true
    else return false
  }


  get sortedNotifications() {
    return _.orderBy(this.notifications, ["created"], ["desc"]).filter((e) =>
      m(e.created).isBefore()
    );
  }

  get todayMessages() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "ChatNotification")
      .forEach((x) => {
        if (this.calculateHours(x.created) <= 24) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisWeekMessages() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "ChatNotification")
      .forEach((x) => {
        if (
          this.calculateHours(x.created) > 24 &&
          this.calculateDays(x.created) <= 7
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisMonthMessages() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "ChatNotification")
      .forEach((x) => {
        if (
          this.calculateDays(x.created) > 7 &&
          this.calculateDays(x.created) <= 30
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get todayEvents() {
    const _notifications = [];
    this.sortedNotifications
      .filter(
        (x) =>
          x.type === "CalendarCommunication" ||
          x.type === "CalendarRsvpNotification"
      )
      .forEach((x) => {
        if (this.calculateHours(x.created) <= 24) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisWeekEvents() {
    const _notifications = [];
    this.sortedNotifications
      .filter(
        (x) =>
          x.type === "CalendarCommunication" ||
          x.type === "CalendarRsvpNotification"
      )
      .forEach((x) => {
        if (
          this.calculateHours(x.created) > 24 &&
          this.calculateDays(x.created) <= 7
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisMonthEvents() {
    const _notifications = [];
    this.sortedNotifications
      .filter(
        (x) =>
          x.type === "CalendarCommunication" ||
          x.type === "CalendarRsvpNotification"
      )
      .forEach((x) => {
        if (
          this.calculateDays(x.created) > 7 &&
          this.calculateDays(x.created) <= 30
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }
  get todayCcrg() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "CCRGUpdates")
      .forEach((x) => {
        if (this.calculateHours(x.created) <= 24) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get todayComments() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "CommentNotification")
      .forEach((x) => {
        if (this.calculateHours(x.created) <= 24) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }
  get todayAcademics() {
    const _notifications = [];
    this.sortedNotifications
      .filter(
        (x) =>
          x.type === "PbisNotification" ||
          x.type === "AlertNotification" ||
          x.type === "AbsentTardyNotification" ||
          x.type === "CCRGUpdates" ||
          x.type === "CCRGUpdate"
      )
      .forEach((x) => {
        if (this.calculateHours(x.created) <= 24) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisWeekCcrg() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "CCRGUpdates")
      .forEach((x) => {
        if (
          this.calculateHours(x.created) > 24 &&
          this.calculateDays(x.created) <= 7
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisWeekComments() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "CommentNotification")
      .forEach((x) => {
        if (
          this.calculateHours(x.created) > 24 &&
          this.calculateDays(x.created) <= 7
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }
  get thisWeekAcademics() {
    const _notifications = [];
    this.sortedNotifications
      .filter(
        (x) =>
          x.type === "PbisNotification" ||
          x.type === "AlertNotification" ||
          x.type === "AbsentTardyNotification" ||
          x.type === "CCRGUpdates" ||
          x.type === "CCRGUpdate"
      )
      .forEach((x) => {
        if (
          this.calculateHours(x.created) > 24 &&
          this.calculateDays(x.created) <= 7
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisMonthCcrg() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "CCRGUpdates")
      .forEach((x) => {
        if (
          this.calculateDays(x.created) > 7 &&
          this.calculateDays(x.created) <= 30
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  get thisMonthComments() {
    const _notifications = [];
    this.sortedNotifications
      .filter((x) => x.type === "CommentNotification")
      .forEach((x) => {
        if (
          this.calculateDays(x.created) > 7 &&
          this.calculateDays(x.created) <= 30
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }
  get thisMonthAcademics() {
    const _notifications = [];
    this.sortedNotifications
      .filter(
        (x) =>
          x.type === "PbisNotification" ||
          x.type === "AlertNotification" ||
          x.type === "AbsentTardyNotification" ||
          x.type === "CCRGUpdates" ||
          x.type === "CCRGUpdate"
      )
      .forEach((x) => {
        if (
          this.calculateDays(x.created) > 7 &&
          this.calculateDays(x.created) <= 30
        ) {
          _notifications.push(x);
        }
      });
    return _notifications;
  }

  isNavSearchVisible: boolean;
  @ViewChild("fsbutton", { static: true }) fsbutton; // the fullscreen button

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public injector: Injector,
    public notificationServices: NotificationServices,
    public profileService: ProfileService,
    private dialog: MatDialog,
    private oauthService: OAuthService,
    private chatService: ChatService,
    public rs: RoleServiceFactoryService
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.profileService.identity.userType;
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    this.role = this.profileService?.role?.toLowerCase();
    this.getLogo(this.role);
    this.chatService.notifications.subscribe((notifications) => {
      console.log(notifications);
      if (notifications?.length > 0) {
        this.notifications = notifications.filter((x) => x.dismissed === false);
      }
      _.forEach(this.notifications, (n: any) => {
        var msg = {} as any;
        try {
          msg = JSON.parse(n.message || "{}");
        } catch {
          msg = {};
        }
        n.avatarUserId =
          msg.studentId ||
          msg.teacherId ||
          msg.senderId ||
          msg.profileId ||
          msg.StaffId ||
          msg.data?.userId;
        if (n.type === "CalendarCommunication") {
          n.avatarUserId = msg.Data?.senderId;
        } else if (n.type === "CalendarRsvpNotification") {
          n.avatarUserId = msg.data.attendeeId;
        } else if (n.type == "CalendarRsvpNotification") {
        }
      });
    });

    var ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = "none";
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on("change", () => {
      if (el)
        el.className = screenfull.isFullscreen
          ? "fa fa-compress"
          : "fa fa-expand";
    });

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });
  }

  getLogo(role) {
    switch (role) {
      case "administrator":
        this.logoSrc = "assets/img/icons/LAUSD-Admin-Portal-Logo.png";
        break;
      case "counselor":
        this.logoSrc = "assets/img/icons/LAUSD-Counselor-Portal-Logo.png";
        break;
      case "teacher":
        this.logoSrc = "assets/img/icons/LAUSD-Teacher-Portal-Logo-alt.png";
        break;
      case "student":
        this.logoSrc = "assets/img/icons/LAUSD-Student-Portal-Logo.png";
        break;
      case "parent":
        this.logoSrc = "assets/img/icons/LAUSD-Parent-Portal-Logo.png";
        break;
      default:
        break;
    }
  }

  saveMessagesName(name, index, type) {
    if (type === "today") {
      this.todayMessagesNames[index] = name;
    } else if (type === "week") {
      this.weekMessagesNames[index] = name;
    } else if (type === "month") {
      this.monthMessagesNames[index] = name;
    }
  }

  saveCommentsName(name, index, type) {
    if (type === "today") {
      this.todayCommentsNames[index] = name;
    } else if (type === "week") {
      this.weekCommentsNames[index] = name;
    } else if (type === "month") {
      this.monthCommentsNames[index] = name;
    }
  }

  getIds(notification: UserNotification, type?) {
    if (type === "senderId") {
      const senderId = JSON.parse(notification?.message)?.senderId;
      if (senderId) {
        return senderId;
      } else {
        const id = JSON.parse(notification?.message)?.StaffId;
        if (id) {
          return id;
        } else {
          return JSON.parse(notification?.message)?.data?.senderId;
        }
      }
    } else if (type === "conversationId") {
      return JSON.parse(notification?.message)?.conversationId;
    } else if (type === "communicationId") {
      const message = JSON.parse(notification?.message);
      return message?.data?.communicationId
        ? message.data.communicationId
        : message.Data.communicationId;
    } else if (type === "studentId") {
      const message = JSON.parse(notification?.message);
      return message.StudentId ? message.StudentId : message.studentId;
    } else if (type === "teacherId") {
      return JSON.parse(notification?.message)?.StaffId;
    } else if (type === "ProfileId") {
      return JSON.parse(notification?.message)?.profileId;
    } else if (type === "profileId") {
      return JSON.parse(notification?.message)?.profileId;
    } else if (type === "category") {
      return JSON.parse(notification?.message)?.data?.sourceType;
    } else if (type === "categoryOnly") {
      const category = JSON.parse(notification?.message)?.data?.sourceType;
      return category[0].toUpperCase() + category.slice(1);
    } else if (type === "entityId") {
      return JSON.parse(notification?.message)?.data?.sourceTypeId;
    } else if (type === "userId") {
      return JSON.parse(notification?.message)?.data?.userId;
    } else {
      return notification.profileId;
    }
  }

  getEntity(notification: UserNotification) {
    const entity = JSON.parse(notification?.message)?.Entity;
    if (entity) {
      const result = entity.toLowerCase() + "s";

      if (result === "reinforcements") {
        return "expectations";
      } else {
        return result;
      }
    } else {
      const message = JSON.parse(notification?.message)?.message;
      if (message.includes("Absent/Tardy")) {
        return "attendance";
      }
    }
  }

  openAcademicNotification(notification: UserNotification) {
    if (notification.type === "AlertNotification") {
      this.router.navigateByUrl(
        `/student/profile/${this.getIds(
          notification,
          "studentId"
        )}/${this.getEntity(notification)}`
      );
    } else if (notification.type === "CCRGUpdates") {
      var customMessage = JSON.parse(notification.message);
      var notes =
        customMessage.entityId !== undefined
          ? `/${customMessage.entityId}`
          : "";
      if (customMessage.Entity === "Checklist") {
        if (this.profileService.identity.userType == this.rs.ParentRole) {
          this.router.navigateByUrl(
            `/${this.profileService.identity.userType.toLocaleLowerCase()}/students/${
              customMessage.StudentId
            }/checklists`
          );
        }
        if (this.profileService.identity.userType == this.rs.CounselorRole) {
          this.router.navigateByUrl(
            `/${this.profileService.identity.userType.toLocaleLowerCase()}/students/${
              customMessage.StudentId
            }/checklists`
          );
        }
        if (this.profileService.identity.userType == this.rs.StudentRole) {
          this.router.navigateByUrl(
            `/${this.profileService.identity.userType.toLocaleLowerCase()}/profile/${
              customMessage.StudentId
            }/checklists`
          );
        }
      } else {
        if (this.profileService.identity.userType == this.rs.StudentRole) {
          this.router.navigateByUrl(
            `/${this.profileService.identity.userType.toLocaleLowerCase()}/profile/${
              customMessage.profileId
            }/ccrg/${customMessage.type || customMessage.category}${notes}`
          );
        } else {
          this.router.navigateByUrl(
            `/${this.profileService.identity.userType.toLocaleLowerCase()}/students/${
              customMessage.profileId
            }/ccrg/${customMessage.type || customMessage.category}${notes}`
          );
        }
      }
    } else if (this.profileService.profile.role.toLowerCase() === "parent") {
      this.router.navigateByUrl(
        `/parent/students/${this.getIds(
          notification,
          "studentId"
        )}/${this.getEntity(notification)}`
      );
    } else {
      this.router.navigateByUrl(
        `/student/profile/${this.getIds(
          notification,
          "studentId"
        )}/${this.getEntity(notification)}`
      );
    }
  }

  openMessageNotification(notification: UserNotification) {
    if (notification.type === "ChatNotification") {
      if (this.profileService.profile?.role?.toLowerCase() === "teacher") {
        this.router.navigateByUrl(
          `/teacher/inbox/folder/${this.getIds(notification, "conversationId")}`
        );
      } else if (
        this.profileService.profile?.role?.toLowerCase() === "parent"
      ) {
        this.router.navigateByUrl(
          `/parent/inbox/folder/${this.getIds(notification, "conversationId")}`
        );
      } else {
        this.router.navigateByUrl(
          `/student/inbox/folder/${this.getIds(notification, "conversationId")}`
        );
      }
    }
  }

  dismissNotification(notification: UserNotification) {
    this.chatService.removeNotification(notification?.id);
  }

  openCommentNotification(notification: UserNotification) {
    const selectedCategory = this.getIds(notification, "category");
    const selectedId = this.getIds(notification, "entityId");
    const studentId = this.getIds(notification, "userId");
    console.log(notification);

    const notifData = {
      category: selectedCategory,
      id: selectedId,
      sId: studentId,
    };
    console.log(notifData);

    this.dialog.open(CommentNotificationWidgetComponent, {
      panelClass: "offwhite-background",
      maxHeight: "70vh",
      maxWidth: "700px",
      // disableClose: true,
      data: notifData,
    });
  }

  toggleDayMessages() {
    this.showTodayMessages = !this.showTodayMessages;
    this.showThisWeekMessages = false;
    this.showThisMonthMessages = false;
  }

  toggleWeekMessages() {
    this.showThisWeekMessages = !this.showThisWeekMessages;
    this.showTodayMessages = false;
    this.showThisMonthMessages = false;
  }

  toggleMonthMessages() {
    this.showThisMonthMessages = !this.showThisMonthMessages;
    this.showTodayMessages = false;
    this.showThisWeekMessages = false;
  }

  toggleDayEvents() {
    this.showTodayEvents = !this.showTodayEvents;
    this.showThisWeekEvents = false;
    this.showThisMonthEvents = false;
  }

  toggleWeekEvents() {
    this.showThisWeekEvents = !this.showThisWeekEvents;
    this.showTodayEvents = false;
    this.showThisMonthEvents = false;
  }

  toggleMonthEvents() {
    this.showThisMonthEvents = !this.showThisMonthEvents;
    this.showTodayEvents = false;
    this.showThisWeekEvents = false;
  }

  toggleDayComments() {
    this.showTodayComments = !this.showTodayComments;
    this.showThisWeekComments = false;
    this.showThisMonthComments = false;
  }

  toggleWeekComments() {
    this.showThisWeekComments = !this.showThisWeekComments;
    this.showTodayComments = false;
    this.showThisMonthComments = false;
  }

  toggleMonthComments() {
    this.showThisMonthComments = !this.showThisMonthComments;
    this.showTodayComments = false;
    this.showThisWeekComments = false;
  }

  toggleDayAcademics() {
    this.showTodayAcademics = !this.showTodayAcademics;
    this.showThisWeekAcademics = false;
    this.showThisMonthAcademics = false;
  }

  toggleWeekAcademics() {
    this.showThisWeekAcademics = !this.showThisWeekAcademics;
    this.showTodayAcademics = false;
    this.showThisMonthAcademics = false;
  }

  toggleMonthAcademics() {
    this.showThisMonthAcademics = !this.showThisMonthAcademics;
    this.showTodayAcademics = false;
    this.showThisWeekAcademics = false;
  }
  toggleDayCcrg() {
    this.showTodayCcrg = !this.showTodayCcrg;
    this.showThisWeekCcrg = false;
    this.showThisMonthCcrg = false;
  }

  toggleWeekCcrg() {
    this.showThisWeekCcrg = !this.showThisWeekCcrg;
    this.showTodayCcrg = false;
    this.showThisMonthCcrg = false;
  }

  toggleMonthCcrg() {
    this.showThisMonthCcrg = !this.showThisMonthCcrg;
    this.showTodayCcrg = false;
    this.showThisWeekCcrg = false;
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  getMessagesName(index, type: string) {
    if (type === "today") {
      return this.todayMessagesNames[index];
    } else if (type === "week") {
      return this.weekMessagesNames[index];
    } else if (type === "month") {
      return this.monthMessagesNames[index];
    }
  }

  getCommentsName(index, type: string) {
    if (type === "today") {
      return this.todayCommentsNames[index];
    } else if (type === "week") {
      return this.weekCommentsNames[index];
    } else if (type === "month") {
      return this.monthCommentsNames[index];
    }
  }

  getDate(date) {
    return m(date);
  }

  openEventNotification(event) {
    const id = this.getIds(event, "communicationId");
    let userType = this.profileService.profile.role;
    if (
      userType == "Administrator" ||
      userType == "Teacher" ||
      userType == "Counselor"
    ) {
      // this.router.navigate([userType.toLocaleLowerCase(), 'events', feed.id])
      this.router.navigate([
        userType.toLocaleLowerCase(),
        "events",
        "id",
        id,
      ]);
    } else {
      this.router.navigate([
        userType.toLocaleLowerCase(),
        "events",
        "id",
        id,
      ]);
    }
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  getMessage(notification: UserNotification) {
    var m1 = {} as any;
    var m2 = {} as any;
    if (notification.type == "CCRGUpdates") {
      m1 = JSON.parse(notification.message);
      m2 = JSON.parse(notification.message);
      if (m1.category) {
        let cat = m1.category;
        m1 = m1.message.replace("Accomplishment", cat);
      } else {
        m1 = JSON.parse(notification.message).Message;
        m2 = JSON.parse(notification.message).message;
      }
    } else {
      m1 = JSON.parse(notification.message).Message;
      m2 = JSON.parse(notification.message).message;
    }

    return m1 || m2;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting("offsidebarOpen");
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting("isCollapsed");
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }

  calculateHours(time) {
    return m().diff(m(time), "hours", true);
  }

  calculateDays(time) {
    return m().diff(m(time), "days", true);
  }

  viewChatNotifications() {
    this.notificationServices.cleanChatNotifications();
    this.router.navigate(["parent", "inbox", "folder", "inbox"]);
  }
  viewCommentNotifications() {
    let username = this.profileService.identity.userIdRole;
    this.notificationServices.cleantCommentsNotifications();

    //Open Modal
    this.dialog.open(TimelineCommentDialogComponent, { maxWidth: "auto" });
  }
  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
}
