import { Identity } from "./../../services/profile.service";
import { AlertsService } from "src/app/shared/services/alerts.service";
import { Component, OnInit, Input } from "@angular/core";
import { ImportantAlertType } from "./models/ImportantAlertType";
import { ImportantAlertModel } from "./models/ImportantAlertModel";
import AsyncComponent from "../common/AsyncComponent";
import { ImportantAlert } from "./models/ImportantAlert";
import * as moment from "moment";
import { ProfileService } from "../../services/profile.service";
import { CommunicationService } from "../../services/communication.service";
import { forEach } from "lodash";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-important-alerts-widget",
  templateUrl: "./important-alerts-widget.component.html",
  styleUrls: ["./important-alerts-widget.component.scss"],
})
export class ImportantAlertsWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: ImportantAlert[] = null;
  @Input() staffId;
  @Input() date;
  @Input() dashboardAlert = false;
  @Input() parentDashboard;
  @Input() studentDashboard;
  @Input() profile;
  @Input() students;
  recipients = [];
  identity = this.profileService.identity;

  constructor(
    private alertServices: AlertsService,
    private communicationService: CommunicationService,
    private profileService: ProfileService,
    private analytics: AnalyticsService
  ) {}
  ngOnInit(): void {}

  ngOnChanges() {
    this.modifyStudentList();
    if (this.model?.length > 0) {
      this.model.forEach((alert) => {
        this.alertDetails(alert);
      });

      console.log(this.model);
    }

    console.log(this.staffId);
  }

  modifyStudentList() {
    if (this.students?.length > 0 && this.profile) {
      this.recipients = [];
      this.recipients.push(this.profile.id);
      this.students.forEach((student) => {
        if (this.recipients.indexOf(student.studentId) < 0) {
          this.recipients.push(student.studentId);
        }
      });
    }
  }

  async dismiss(alertId: string, index) {
    this.analytics.sendEvent("User Click", "Dismiss Alert");
    await this.alertServices.dismissAsync(alertId).toPromise();
    this.model.splice(index, 1);
  }

  alertDetails(alert) {
    if (alert && alert.communicationId) {
      this.communicationService
        .getCommunicationById(alert.communicationId)
        .subscribe((result) => {
          alert.staffId = result.staffId;
          if (this.parentDashboard && this.recipients?.length > 0) {
            alert.audience = result.students.filter(
              (element) => this.recipients.indexOf(element) !== -1
            );
          }
        });
    }
  }

  getDate(date) {
    return moment(date).format("MM/DD/YYYY HH:mm A");
  }

  checkType(item) {
    return typeof item === "string" ? false : true;
  }

  getAlertTypeName(value: number) {
    return ImportantAlertType[value].toLowerCase();
  }
}
