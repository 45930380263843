import { RoleServiceFactoryService } from "./role-service-factory.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";
import { ProfileService } from "./profile.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class StaffSettingsService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; //"https://localhost:44315";
  private _currentSetting = new BehaviorSubject<any>(null);
  public isNotificationEnabled: boolean = true;
  public isAvailable: boolean = true;
  public quietSettings: any;
  public TeacherStatus = {
    Available: "Available",
    Teaching: "Teaching",
    Projecting: "Projecting",
    DoNotDisturb: "DoNotDisturb",
  };
  public CounselorStatus = {
    Available: "Available",
    Counseling: "Counseling",
    DoNotDisturb: "DoNotDisturb",
  };
  public AdminStatus = {
    Available: "Available",
    Administrating: "Administrating",
    DoNotDisturb: "DoNotDisturb",
  };
  public TeacherStatusList = [
    {
      id: this.TeacherStatus.Available,
      value: "Status - Available",
    },
    {
      id: this.TeacherStatus.Teaching,
      value: "Status - Teaching",
    },
    {
      id: this.TeacherStatus.Projecting,
      value: "Status - Projecting",
    },
    {
      id: this.TeacherStatus.DoNotDisturb,
      value: "Status - Do not disturb",
    },
  ];
  public CounselorStatusList = [
    {
      id: this.CounselorStatus.Available,
      value: "Status - Available",
    },
    {
      id: this.CounselorStatus.Counseling,
      value: "Status - Counseling",
    },
    {
      id: this.CounselorStatus.DoNotDisturb,
      value: "Status - Do not disturb",
    },
  ];
  public AdminStatusList = [
    {
      id: this.AdminStatus.Available,
      value: "Status - Available",
    },
    {
      id: this.AdminStatus.Administrating,
      value: "Status - Administrating",
    },
    {
      id: this.AdminStatus.DoNotDisturb,
      value: "Status - Do not disturb",
    },
  ];

  public currentCounselorStatus = this.CounselorStatus.Counseling;
  public currentTeacherStatus = this.TeacherStatus.Teaching;
  public currentAdminStatus = this.AdminStatus.Administrating;
  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    public profileService: ProfileService,
    private rs: RoleServiceFactoryService,
  ) {
    this.api.baseUrl = this.baseUrl;
  }

  get settings() {
    return this._currentSetting.asObservable();
  }

  setSettings(settings) {
    this._currentSetting.next(settings);
  }
  getSettings(staffId: string): Observable<any> {
    let path = `${this.baseUrl}/v1/staff/${staffId}/quiet-hours`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  silentSettings(staffId: string): Observable<any> {
    let path = `${this.baseUrl}/v1/staff/${staffId}/quiet-hours`;
    return this.api.getWithParams(path, { spinner: "no" }).pipe(
      tap((res: any) => {
        if (this.profileService.identity.userType === this.rs.TeacherRole) {
          this.updateStaffQuietSettings(res);
        }
        if (this.profileService.identity.userType === this.rs.CounselorRole) {
          this.updateStaffQuietSettings(res);
        }
        if (this.profileService.identity.userType === this.rs.AdminRole) {
          this.updateStaffQuietSettings(res);
        }
      }),
      map((response) => response as any)
    );
  }

  updateStaffQuietSettings(res) {
    this.quietSettings = res;
    if (this.profileService.identity.userType === this.rs.TeacherRole) {
      this.isNotificationEnabled = res.isNotificationsEnabled;
      this.isAvailable = res.isAvailable;
      if (res.quietHours.isNotDisturb)
        this.currentTeacherStatus = this.TeacherStatus.DoNotDisturb;
      if (res.quietHours.isProjecting)
        this.currentTeacherStatus = this.TeacherStatus.Projecting;
      if (res.quietHours.isTeaching)
        this.currentTeacherStatus = this.TeacherStatus.Teaching;
      if (res.quietHours.isAvailable)
        this.currentTeacherStatus = this.TeacherStatus.Available;
      if (this.checkQuietHours(res)) {
        this.currentTeacherStatus = this.TeacherStatus.DoNotDisturb;
      }
    }
    if (this.profileService.identity.userType === this.rs.CounselorRole) {
      this.isNotificationEnabled = res.isNotificationsEnabled;
      this.isAvailable = res.isAvailable;
      if (res.quietHours.isNotDisturb)
        this.currentCounselorStatus = this.CounselorStatus.DoNotDisturb;
      if (res.quietHours.isTeaching)
        this.currentCounselorStatus = this.CounselorStatus.Counseling;
      if (res.quietHours.isAvailable)
        this.currentCounselorStatus = this.CounselorStatus.Available;
      if (this.checkQuietHours(res)) {
        this.currentCounselorStatus = this.CounselorStatus.DoNotDisturb;
      }
    }
    if (this.profileService.identity.userType === this.rs.AdminRole) {
      this.isNotificationEnabled = res.isNotificationsEnabled;
      this.isAvailable = res.isAvailable;
      if (res.quietHours.isNotDisturb)
        this.currentAdminStatus = this.AdminStatus.DoNotDisturb;
      if (res.quietHours.isTeaching)
        this.currentAdminStatus = this.AdminStatus.Administrating;
      if (res.quietHours.isAvailable)
        this.currentAdminStatus = this.AdminStatus.Available;
      if (this.checkQuietHours(res)) {
        this.currentAdminStatus = this.AdminStatus.DoNotDisturb;
      }
    }
  }

  checkQuietHours(quietHours){
    const format = 'HH:mm:ss';
    let today = moment().format('dddd').toLocaleLowerCase();
    let date = moment().format(format);
    let now = moment(date, format);
    if(quietHours.quietHours[today]){
      const start = `${today}StartTime`;
      const end = `${today}EndTime`
      let startTime = moment(quietHours.quietHours[start], format);
      let endTime = moment(quietHours.quietHours[end], format);
      if(now.isBetween(startTime, endTime)){
        return true
      } else return false
    }
  }

  update(staffId: string, settings: any): Observable<any> {
    let path = `${this.baseUrl}/v1/staff/${staffId}/settings`;
    return this.api.put(path, settings).pipe(
      tap((res: any) => {
        this.isNotificationEnabled = res.isNotificationEnabled;
        this.isAvailable = res.isAvailable;
        this.quietSettings = res;
      }),
      map((response) => response as any)
    );
  }

  patch(staffId: string, settings): Observable<any> {
    let path = `${this.baseUrl}/v1/staff/${staffId}/quiet-hours`;
    return this.api.patch(path, settings).pipe(
      tap((res: any) => {
        this.isNotificationEnabled = res.isNotificationEnabled;
        this.isAvailable = res.isAvailable;
        this.updateStaffQuietSettings(res);
      }),
      map((response) => response as any)
    );
  }
}
