import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(private router:Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              return;
            }
            if (err.status == 403) {
              this.router.navigate(["auth","404"]);
            }
            // this.router.navigate(["auth","login"]);
          }
        }
      )
    );
  }
}
